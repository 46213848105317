import '../../Components_Style/Internal/Furniture_Display_Card.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import ContactForDisplay from '../Contact_For_Display';



export default function FurnitureDisplayCard(productProps) {
  let badgeText 
    if (productProps.soldOut === 0) {
      badgeText = "Sold Out !!"
    } else if (productProps.specialOffer === true) {
      badgeText = "Special"
    }

    let oldOffer; 
      if (productProps.specialOffer === true) {
        oldOffer = "Old_Offer_Active";
      } else if (productProps.specialOffer === false)
      {
        oldOffer ="Old_Offer_Inactive";
      }

      let multiOptions; 
      if (productProps.multiOptions === true) {
        multiOptions = "MultiOptions_Active";
      } else if (productProps.multiOptions === false)
      {
        multiOptions ="MiltiOptions_Inactive";
      }

  return (

  <div className='Container_Product_Display_Card'>

    <div className='Container_Product_Display'>
        
        <Carousel infiniteLoop autoPlay>

        <section className='Display_Images'>
          <img src={productProps.img_1} alt=''/>
        </section>
        <section className='Display_Images'>
        <img src={productProps.img_2} alt=''/>
        </section>
        <section className='Display_Images'>
        <img src={productProps.img_3} alt=''/>
        </section>
        <section className='Display_Images'>
        <img src={productProps.img_4} alt=''/>
        </section>
        <section className='Display_Images'>
        <img src={productProps.img_5} alt=''/>
        </section>
        
        </Carousel>
        {badgeText && <div className="Sold_Out_Badge">{badgeText}</div>}
        
        <h3 className='Text'>{productProps.name}</h3>
        <h3 className='Text'>{productProps.code}</h3>
        <section>
          <h4 className='Text'>color options</h4>

          <p>{productProps.color}</p>
        </section>
        <br/>
        <h1 className='Text'><sup>Unit Price:</sup>
        <br/> {productProps.type}
        <br/> {productProps.priceRand}
        <sub>{productProps.priceCents}</sub><br/>

        <s id="old" className={oldOffer}>
          <h4>{productProps.oldOfferRand}
          <sub>{productProps.oldOfferCents}</sub></h4>
        </s></h1>

        <div id="multi" className={multiOptions}>
        <h1>  
        <br/> {productProps.type2}
        <br/> {productProps.priceRand2}
        <sub>{productProps.priceCents2}</sub><br/>

        <br/> {productProps.type3}
        <br/> {productProps.priceRand3}
        <sub>{productProps.priceCents3}</sub></h1>
        </div>

        <section className='Display_Description'>
          <h3>Description</h3>

          <p>{productProps.description}</p>

        </section>

        <section className='Display_Sizes'>
          <h3>Size</h3>

          <p>{productProps.size}</p>
        </section>
        <br/>
        
        <section>
          <h3>Delivery within Gauteng. Prices differ outside Gauteng.</h3>
          <h4>R900</h4>
          {/* {productProps.inProvinceDelivery} */}
        </section>
        <br/>

        <ContactForDisplay/>
        

    </div>
  
  </div>
  
  );
}
