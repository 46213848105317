import '../../../../Pages_Style/Products.css';
import '../../../../Components_Style/Page_Header.css';
import ProductCardsData from '../../../../Components/Internal/Product_Data';
import ProductCard from '../../../../Components/Internal/Product_Card';

const productElements = ProductCardsData.map(productCard => {
    return <ProductCard 
    key={productCard.id}
    group={productCard.group}
    {...productCard}
    />
}) 

export default function Mirrors_Kists() {
  
    return (
   
    <div className='Container_Product_Display'>

            <div className='Display_Row Row_1'>

                <section className='Cards_Display card1'>
                    {productElements[300]}
                </section>
                <section className='Cards_Display'>
                    {productElements[301]}
                </section>
                <section className='Cards_Display'>
                    {productElements[302]}
                </section>
                <section className='Cards_Display'>
                    {productElements[303]}
                </section>
                <section className='Cards_Display'>
                    {productElements[304]}
                </section>

            </div>

             <div className='Display_Row Row_2'>

                <section className='Cards_Display'>
                    {productElements[315]}
                </section>
                <section className='Cards_Display'>
                    {productElements[316]}
                </section>
                <section className='Cards_Display'>
                    {productElements[317]}
                </section>
                <section className='Cards_Display'>
                    {productElements[318]}
                </section>
                <section className='Cards_Display'>
                    {productElements[305]}
                </section>

            </div>

            <div className='Display_Row Row_3'>

                <section className='Cards_Display'>
                    {productElements[306]}
                </section>
                <section className='Cards_Display'>
                    {productElements[307]}
                </section>
                <section className='Cards_Display'>
                    {productElements[309]}
                </section>
                <section className='Cards_Display'>
                    {productElements[310]}
                </section>
                <section className='Cards_Display'>
                    {productElements[311]}
                </section>

            </div>

            <div className='Display_Row Row_4'>

                <section className='Cards_Display'>
                    {productElements[312]}
                </section>
                <section className='Cards_Display'>
                    {productElements[313]}
                </section>
                <section className='Cards_Display'>
                    {productElements[314]}
                </section>
                <section className='Cards_Display'>
                    {productElements[324]}
                </section>
                <section className='Cards_Display'>
                    {productElements[399]}
                </section>

            </div> 

        </div>
       
  );
}
