import '../Pages_Style/Home.css';
import '../Components_Style/Page_Header.css'
import OnSpecial from '../Components/On_Special/Specials_Combo';
import NavHome from '../Components_Images/NAV/Home.png';
import { motion } from 'framer-motion';

export default function Home() {
  return (
   <motion.div className='Container_Home'
   initial={{widths: 0}}
   animate={{width: '100%'}}
   exit={{opacity : 0}}
   >

    <div id='/' className='Container_PageSplitter pageHeaderhome'>

      <section className='Splitter_Active_PageLogo PageSplitter'>
        <img src={NavHome} alt='' width={45}/>
      </section>

      <section className='PageSplitter_Navbar'>
        <h1>Home</h1>
      </section>

    </div>

    <div className='Container_Welcome'>
      <div className='Welcome_Box_1'>
        {/* Required Introduction / Advertisement Video */}

        <section className='Intro_Video'>
          <h1>Introduction Tutorial</h1>

          <section className='Intro_Video_1361px'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/r9IU9LrmCUw?si=IQ34R9BVLtRL22uU" title="YouTube video player" frameBorder="0" 
            allowFullScreen>
            </iframe>
          </section>

          <section className='Intro_Video_481px-1360px'>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/6bBvdddRULI?si=EHzR2tcd27Fx2DKN" 
          title="YouTube video player" frameBorder="0" 
          allowFullScreen>
          </iframe>
          </section>

          <section className='Intro_Video_0-480px'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/6K8gyRPYilY?si=yBbCuh9rt8DpHN6i" title="YouTube video player" frameBorder="0" 
            allowFullScreen>
            </iframe>
          </section>

        

          <h1>Welcome to the FurnitureDeranged online store</h1>
          <p>Indulge in the epitome of epulence with FurnitureDeranged. Welcome to a word where luxury becomes accessible, exceptional craftsmanship reigns supreme. At FurnitureDeranged, we take pride in curating a collection of exquisite, high-end furniture that exemplifies sophistication & style. By sourcing directly from the manufacturers, we eliminate unnecessary costs & pass those savings on to you. from sleek modern designs to timeless classics, each piece in our collection is meticously crafted with the finest materials & attention to detail. Elevate your living with our exclusive selection, knowing that you're investing in quality that will stand the test of time. Discover a world of refined elegance at FurnitureDeranged, where luxury is just a click away. Start your journey to an exquisitly furnished home today.</p>
        </section>

      </div>

      <div className='Welcome_Box_2'>
        
      <section className='Welcome_Word_Section_1'>
      <h2>Placing an Order</h2>
    <p>
    To place an order, simply call, message or email one of our Agents & provide the name with the order Code <strong>(-FD aab-)</strong> and our sales representative will get in contact.
    </p>
      </section>
      <section className='Welcome_Word_Section_2'>
      <h2>Established in South Africa Gauteng</h2>
    <p>
    FurnitureDeranged.com is a new online furniture retailer that has been formed to allow smaller local stores & localized manufacturers an opertunity to expand their market reach, as well as make furniture sales more accessible to our local communities. 
    </p>
      </section>
      <section className='Welcome_Word_Section_3'>
      <h2>Our Mission</h2>
    <p>
    The goal FurnitureDeranged.com is to expand the furniture industry in South Africa in order to provide more oppertunities & trainining skilled labour in the industry. During this process we can build more luxury furniture as well as make the process of acquiring furniture more accessibleto the general public.
    </p>
      </section>

      </div>
    </div>

{/* Specials Section comes here */}
    <OnSpecial/>

   </motion.div>
  );
}
