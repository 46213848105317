import '../../../../Pages_Style/Products.css';
import '../../../../Components_Style/Page_Header.css';
import ProductCardsData from '../../../../Components/Internal/Product_Data';
import ProductCard from '../../../../Components/Internal/Product_Card';

const productElements = ProductCardsData.map(productCard => {
    return <ProductCard 
    key={productCard.id}
    group={productCard.group}
    {...productCard}
    />
}) 

export default function Couches() {
  
    return (
   
        <div className='Container_Product_Display'>

        <div className='Display_Row Row_1'>

            <section className='Cards_Display card1'>
                {productElements[200]}
            </section>
            <section className='Cards_Display'>
                {productElements[201]}
            </section>
            <section className='Cards_Display'>
                {productElements[202]}
            </section>
            <section className='Cards_Display'>
                {productElements[203]}
            </section>
            <section className='Cards_Display'>
                {productElements[204]}
            </section>

        </div>

         <div className='Display_Row Row_2'>

            <section className='Cards_Display'>
                {productElements[205]}
            </section>
            <section className='Cards_Display'>
                {productElements[206]}
            </section>
            <section className='Cards_Display'>
                {productElements[207]}
            </section>
            <section className='Cards_Display'>
                {productElements[208]}
            </section>
            <section className='Cards_Display'>
                {productElements[209]}
            </section>

        </div>

        {/*<div className='Display_Row Row_3'>

            <section className='Cards_Display'>
                {productElements[1]}
            </section>
            <section className='Cards_Display'>
                {productElements[2]}
            </section>
            <section className='Cards_Display'>
                {productElements[3]}
            </section>
            <section className='Cards_Display'>
                {productElements[4]}
            </section>
            <section className='Cards_Display'>
                {productElements[5]}
            </section>

        </div>

        <div className='Display_Row Row_4'>

            <section className='Cards_Display'>
                {productElements[1]}
            </section>
            <section className='Cards_Display'>
                {productElements[2]}
            </section>
            <section className='Cards_Display'>
                {productElements[3]}
            </section>
            <section className='Cards_Display'>
                {productElements[4]}
            </section>
            <section className='Cards_Display'>
                {productElements[5]}
            </section>

        </div> */}

    </div>
       
  );
}
