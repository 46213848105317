import '../../Pages_Style/Products.css';
import '../../Components_Style/Page_Header.css';import React, { forwardRef, useEffect, useState} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ComboDeals from './Pages_Categories/Other/Combo_Deals';
/* import ComboSpecials from '../../Components/On_Special/Combo_Specials'; */
import ComboTopSellers from '../../Components/Top_Sellers/Combo_Top_Sellers';
import ComboLounge from './Pages_Categories/Other/Combo_Lounge';
import ComboDining from './Pages_Categories/Other/ComboDining';
// import ProductCardsData from '../Components/Internal/Product_Data';
// import ProductCard from '../Components/Internal/Product_Card';

// const productElements = ProductCardsData.map(productCard => {
//     return <ProductCard 
//         key={productCard.id}
//         {...productCard}
//     />
// }) 

function ComboOffers({},ref) {
    const [furnitureState , setFurnitureState] = useState('comboDeals');
    
    const [fullSuiteState , setFullSuiteState] = useState(false)
    const [partialSuite_1State , setPartialSuite_1State] = useState(false)
    const [partialSuite_2State , setPartialSuite_2State] = useState(false)
    /* const [partialSuite_3State , setPartialSuite_3State] = useState(false)
    const [partialSuite_4State , setPartialSuite_4State] = useState(false) */

    useEffect(() => {
        furnitureState === "comboDeals" ? setFullSuiteState(true) : setFullSuiteState(false);
        furnitureState === "comboLounge" ? setPartialSuite_1State(true) : setPartialSuite_1State(false);
        furnitureState === "comboDining" ? setPartialSuite_2State(true) : setPartialSuite_2State(false);
        /* furnitureState === "bedOther" ? setPartialSuite_3State(true) : setPartialSuite_3State(false);
        furnitureState === "mirrorsKists" ? setPartialSuite_4State(true) : setPartialSuite_4State(false); */
    }, [furnitureState]);

    return (
   <div id='NavProduct' className='Container_Products' ref={ref}>
    
    <div className='Top_Seller_Cards_Container'>
        <ComboTopSellers/>
    </div>

    {/* <div className='Container_Specials'>
        <ComboSpecials/>
    </div> */}
    
            <center><h2 id='Sub_Categories'>Select Sub Catagory</h2></center>

    <div  className='Container_Sub_Categories_Options'>
            
        <div className='Sub_Categories_Options'>

            <div className='Selected'>
                
            </div>
            <div className='Select_Box'>
                <div className='Container_Options_Menu'>

                    <AnchorLink href='#Sub_Categories'>
                    <select 
                    value={furnitureState}
                    onChange={(e) => {
                        const selectedFurniture = e.target.value;
                        setFurnitureState(selectedFurniture)
                    }}>
                        <option value='comboDeals' label='Combos Sets'>
                        
                        </option>
                        <option value='comboLounge' label='Lounge Combos'>
                        
                        </option>
                        <option value='comboDining' label='Dining Combos'>
                        
                        </option>
                        
                    </select>
                    </AnchorLink>

                </div>
            </div>

        </div>

    </div>
    
    <div className='Container_Listed_Sub_Categories'>

        { fullSuiteState && <ComboDeals/> }
        { partialSuite_1State && <ComboLounge/> }
        { partialSuite_2State && <ComboDining/> }
        
    </div>
       

   </div>
  );
}

export default forwardRef(ComboOffers)

   
   

       

//    </div>


