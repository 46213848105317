import '../../Pages_Style/Products.css';
import '../../Components_Style/Page_Header.css';import React, { forwardRef, useEffect, useState} from 'react';
import BedroomTopSellers from '../../Components/Top_Sellers/Bedroom_Top_Sellers';
import BedroomOnSpecial from '../../Components/On_Special/Bedroom_Specials';
import BedSuites from './Pages_Categories/Bedroom/BedSuites';
import HeadboardPedestals from './Pages_Categories/Bedroom/Headboards_Pedestals';
import Dressing from './Pages_Categories/Bedroom/Dressing';
// import BedOther from './Pages_Categories/Bedroom/BedOther';
import Mirrors_Kists from './Pages_Categories/Other/Mirrors_Kists';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import ProductCardsData from '../Components/Internal/Product_Data';
// import ProductCard from '../Components/Internal/Product_Card';

// const productElements = ProductCardsData.map(productCard => {
//     return <ProductCard 
//         key={productCard.id}
//         {...productCard}
//     />
// }) 

function BedroomSuites({},ref) {
    const [furnitureState , setFurnitureState] = useState('bedSuites');
    
    const [fullSuiteState , setFullSuiteState] = useState(false)
    const [partialSuite_1State , setPartialSuite_1State] = useState(false)
    const [partialSuite_2State , setPartialSuite_2State] = useState(false)
    // const [partialSuite_3State , setPartialSuite_3State] = useState(false)
    const [partialSuite_4State , setPartialSuite_4State] = useState(false)

    useEffect(() => {
        furnitureState === "bedSuites" ? setFullSuiteState(true) : setFullSuiteState(false);
        furnitureState === "headboardPedestals" ? setPartialSuite_1State(true) : setPartialSuite_1State(false);
        furnitureState === "dressing" ? setPartialSuite_2State(true) : setPartialSuite_2State(false);
        // furnitureState === "bedOther" ? setPartialSuite_3State(true) : setPartialSuite_3State(false);
        furnitureState === "mirrorsKists" ? setPartialSuite_4State(true) : setPartialSuite_4State(false);
    }, [furnitureState]);

    return (
   <div id='NavProduct' className='Container_Products' ref={ref}>
    
    <div className='Top_Seller_Cards_Container'>
        <BedroomTopSellers/>
    </div>

    <div className='Container_Specials'>
        <BedroomOnSpecial/>
    </div>
    
            <center><h2 id='Sub_Categories'>Select Sub Catagory</h2></center>

    <div  className='Container_Sub_Categories_Options'>
            
        <div className='Sub_Categories_Options'>

            <div className='Selected'>
                
            </div>
            <div className='Select_Box'>
                <div className='Container_Options_Menu'>

                    <AnchorLink href='#Sub_Categories'>
                    <select 
                    value={furnitureState}
                    onChange={(e) => {
                        const selectedFurniture = e.target.value;
                        setFurnitureState(selectedFurniture)
                    }}>
                        <option value='bedSuites' label='Bedroom Suites'>
                        
                        </option>
                        <option value='headboardPedestals' label='Headboard & Pedestals'>
                        
                        </option>
                        <option value='dressing' label='Dressing Tables'>
                        
                        </option>
                        <option value='mirrorsKists' label='Mirrors & Kists'>
                        
                        </option>
                        {/* <option value='bedOther' label='Other'>
                        
                    </option> */}
                    </select>
                    </AnchorLink>

                </div>
            </div>

        </div>

    </div>
    
    <div className='Container_Listed_Sub_Categories'>

        { fullSuiteState && <BedSuites/> }
        { partialSuite_1State && <HeadboardPedestals/> }
        { partialSuite_2State && <Dressing/> }
        { partialSuite_4State && <Mirrors_Kists/>  }
        {/* { partialSuite_3State && <BedOther/>  } */}
        
    </div>
       

   </div>
  );
}

export default forwardRef(BedroomSuites)

   
   

       

//    </div>


