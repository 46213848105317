import React, { forwardRef, useEffect, useState} from 'react';
import '../Pages_Style/Products.css';
import '../Components_Style/Page_Header.css';
import NavProducts from '../Components_Images/NAV/Product.png';
// import ProductCardsData from '../Components/Internal/Product_Data';
// import ProductCard from '../Components/Internal/Product_Card';
import BedroomSuites from './Product_Pages/Bedroom_Listings';
import DiningSuites from './Product_Pages/Dining_Listings';
import LoungeSuites from './Product_Pages/Lounge_Listings';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ComboOffers from './Product_Pages/Combo_Offers';
// import OfficeSets from './Product_Pages/Office_Listings';
// import OutdoorSets from './Product_Pages/Outdoor_Listings';

// const productElements = ProductCardsData.map(productCard => {
//     return <ProductCard 
//         key={productCard.id}
//         {...productCard}
//     />
// }) 

function Products({},ref) {
    const [furnitureState , setFurnitureState] = useState('bedroomSuites');
    
    const [bedroomState , setBedroomState] = useState(false)
    const [diningState , setDiningState] = useState(false)
    const [loungeState , setLoungeState] = useState(false)
    const [comboState , setComboState] = useState(false)
    // const [officeState , setOfficeState] = useState(false)
    // const [outdoorState , setOutdoorState] = useState(false)

    useEffect(() => {
        furnitureState === "bedroomSuites" ? setBedroomState(true) : setBedroomState(false);
        furnitureState === "diningSuites" ? setDiningState(true) : setDiningState(false);
        furnitureState === "loungeSuites" ? setLoungeState(true) : setLoungeState(false);
        furnitureState === "comboOffers" ? setComboState(true) : setComboState(false);
        // furnitureState === "officeSets" ? setOfficeState(true) : setOfficeState(false);
        // furnitureState === "outdoorSets" ? setOutdoorState(true) : setOutdoorState(false);
    }, [furnitureState]);

    return (
   <div id='NavProduct' className='Container_Products' ref={ref}>
    
    <div  className='Container_PageSplitter pageHeaderproduct'>
        <div className='Splitter_Active_PageLogo PageSplitter'>
            <img src={NavProducts} alt='' width='45px'/>
        </div>

        <div className='PageSplitter_Navbar'>
            
            <div className='Selected'>
                <h2>Select Catagory</h2>
            </div>
            <div className='Select_Box'>
                <div className='Container_Options_Menu'>

                    <AnchorLink href='#NavProduct'>
                    <select 
                    value={furnitureState}
                    onChange={(e) => {
                        const selectedFurniture = e.target.value;
                        setFurnitureState(selectedFurniture)
                    }}>
                            
                        <option value='bedroomSuites' label='Bedroom'>
                        
                        </option>
                        <option value='diningSuites' label='Dining'>
                        
                        </option>
                        <option value='loungeSuites' label='Lounge'>
                        
                        </option>
                        <option value='comboOffers' label='Combo Offers'>

                        </option>
                        {/* <option value='officeSets' label='Office'>
                        
                        </option>
                        <option value='outdoorSets' label='Outdoor'>
                        
                    </option> */}
                    </select>
                    </AnchorLink>

                </div>
            </div>

        </div>

    </div>
    
    <div className='Container_Listed_Sub_Categories'>

        { bedroomState && <BedroomSuites/> }
        { diningState && <DiningSuites/> }
        { loungeState && <LoungeSuites/> }
        { comboState && <ComboOffers/> }
        {/* { officeState && <OfficeSets/>  }
        { outdoorState && <OutdoorSets/> } */}
        
    </div>
       

   </div>
  );
}

export default forwardRef(Products);