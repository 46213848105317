import '../Pages_Style/Contact_For_Display.css';
import '../Components_Style/Page_Header.css';
import NavContact from '../Components_Images/NAV/Contact.png';
import FacebookIMG from '../Components_Images/LOGO/FB.png';
import InstagramIMG from '../Components_Images/LOGO/IG.png';
import ContactCard2 from '../Components/Internal/Contact_Card_2';
import ContactCardsData from '../Components/Internal/Contact_Data';
// import Newsletter from '../Components/Newsletter';

export default function ContactForDisplay() {

  const contactElements = ContactCardsData.map(contactCard2 => {
    return <ContactCard2 
        key={contactCard2.id}
        {...contactCard2}
    />
}) 

  return (
   <div className='Container_Contact2'>

    <div className='Container_Active_Contacts2'>

    <div className='Container_Contact2_Box'>

            <h2> Get in contact with one of our Sales individuals</h2>

        <div className='Contact2_Box'>

            <section className='Contact2_Display'>
                {contactElements[0]}
            </section>
    
            <section className='Contact2_Display'>
                {contactElements[1]}
            </section>
    
            <section className='Contact2_Display'>
                {contactElements[2]}
            </section>

        </div>

            <div className='Container_Contact2_Box_Scrol_Header'><br/>
                <h3>---- Scroll ----</h3>
            </div>
    </div>

    {/* <Newsletter/> */}

   </div>

   

   </div>
  );
}
