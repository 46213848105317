import '../../Components_Style/Internal/Product_Cards.css';
import TestIMG from '../../Components_Images/Product_Listing/Sample.jpg';
import { Link } from 'react-router-dom';



export default function ProductCard(productProps) {

  const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

  let badgeText 
    if (productProps.soldOut === 0) {
      badgeText = "Sold Out !!"
    } else if (productProps.specialOffer === true) {
      badgeText = "Special"
    }

    let oldOffer; 
      if (productProps.specialOffer === true) {
        oldOffer = "Old_Offer_Active";
      } else if (productProps.specialOffer === false)
      {
        oldOffer ="Old_Offer_Inactive";
      }

  return (
    <Link to={'Furniture/' + productProps.id} onClick={scrollToTop}>
   <div className='Container_Product_Card'>
        {badgeText && <div className="Sold_Out_Badge">{badgeText}</div>}
        <center><img src={productProps.img} alt=''/></center>
        <h3 id='' className='Text Name_Text'>{productProps.name}</h3>
        <h4 className='Text'><sup>Unit Price:</sup>
        <br/> {productProps.priceRand}
        <sub>{productProps.priceCents}</sub></h4>
        <center>
        <s id="old" className={oldOffer}>
          <h4>{productProps.oldOfferRand}
          <sub>{productProps.oldOfferCents}</sub></h4>
        </s>
        </center>
   </div>
    </Link>
  );
}
