import '../../Components_Style/Internal/Scroll_To_Top.css'
import ScrollIcon from '../../Components_Images/NAV/Scroll_Icon.png'
import React, { useState, useEffect } from 'react';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  // This useEffect will listen for scroll events and update the visibility state accordingly.
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to handle the scroll event and determine whether to show the button.
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Function to scroll to the top when the button is clicked.
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling behavior
    });
  };

  return (
    <div className={`scroll-to-top ${isVisible ? 'scroll' : 'scroll_Inactive'}`} id='ScrollBtn' onClick={scrollToTop}>
      <img src={ScrollIcon} width={30} alt='Scroll To Top'/>
    </div>
  );
}

export default ScrollToTopButton;