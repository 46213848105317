import '../Components_Style/Navbar.css'
import Logo_01 from '../Components_Images/LOGO/FSD_SV.png';
import Logo_02 from '../Components_Images/LOGO/Logo.png';
import NavHome from '../Components_Images/NAV/Home.png';
import NavProduct from '../Components_Images/NAV/Product.png';
import NavInfo from '../Components_Images/NAV/Info.png';
import NavContact from '../Components_Images/NAV/Contact.png';
import SocialFacebook from '../Components_Images/NAV/FB.png';
import SocialInstagram from '../Components_Images/NAV/IG.png';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ScrollToTopButton from './Internal/Scroll_To_Top_Btn';
import { isMobile } from 'react-device-detect';

export default function Navbar() {

    // Check if the user is on a mobile device
    const isMobileDevice = isMobile;

    const redirectToFacebook = () => {
        if (isMobileDevice) {
          // Check if the Facebook app is installed based on the user agent
          const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
          const isFacebookAppInstalled = /FBAN|FBAV/i.test(userAgent);
      
          if (isFacebookAppInstalled) {
            // Redirect to the Facebook app using a deep link
            window.location.href = 'fb://page/?id=61551751746447';
          } else {
            // Redirect to the Facebook Page in the mobile device's web browser
            const url = 'https://www.facebook.com/61551751746447';
            window.location.href = url;
          }
        } else {
          // Redirect desktop users to the website version of your business page in a new tab
          const url = 'https://www.facebook.com/61551751746447';
          window.open(url, '_blank');
        }
      };

      const redirectToInstagram = () => {
        if (isMobileDevice) {
          // Check if the Instagram app is installed based on the user agent
          const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
          const isInstagramAppInstalled = /instagram/i.test(userAgent);
      
          if (isInstagramAppInstalled) {
            // Redirect to the Instagram app using a deep link
            window.location.href = 'instagram://user?username=furniturederanged';
          } else {
            // Redirect to the Instagram profile in the mobile device's web browser
            const url = 'https://www.instagram.com/furniturederanged';
            window.location.href = url;
          }
        } else {
          // Redirect desktop users to the Instagram profile in a new tab
          const url = 'https://www.instagram.com/furniturederanged';
          window.open(url, '_blank');
        }
      };
      
      


    //Scroll to top
    const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

    return (
        <nav className='Container_Main_Navbar'>

            <div className='Container_Logo'>
                <section>
                    <a href='/'>
                <img id='Logo_01' src={Logo_01} alt='Failed Loading' width='100%'/>
                <img id='Logo_02' src={Logo_02} alt='Failed Loading' width='100%'/>
                    </a>
                </section>
            </div>

            <div className='Container_Navigation'>
                <section>
                <center>
                    <Link to='/' onClick={scrollToTop}>
                        <img src={NavHome} alt='Failed Loading' width='100%'/>
                        <br/>Home
                    </Link><br/>
                    <AnchorLink href='#NavProduct'>
                        <img src={NavProduct} alt='Failed Loading' width='100%'/>
                        <br/>Product
                    </AnchorLink><br/>
                    <AnchorLink href='#NavAbout'>
                        <img src={NavInfo} alt='Failed Loading' width='100%'/>
                        <br/>About Us
                    </AnchorLink><br/>
                    <AnchorLink href='#NavContact'>
                        <img src={NavContact} alt='Failed Loading' width='100%'/>
                        <br/>Contact
                    </AnchorLink>
                </center>
                </section>
            </div>

            <div className='Container_Social_Links'>
                <section>
                    <center>
                        <button onClick={redirectToFacebook}><img src={SocialFacebook} alt='Failed Loading' width='100%'/></button>
                        <button onClick={redirectToInstagram}><img src={SocialInstagram} alt='Failed Loading' width='100%'/></button>
                    </center>
                </section>
            </div>

            <ScrollToTopButton/>

        </nav>
    )
}