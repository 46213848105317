import { Routes, Route, useLocation} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react';
import './App.css';
import icon from './Components_Images/LOGO/favicon.ico';
import Navbar from './Components/Navbar';
import Home from './Pages/Home';
import FurnitureDisplay from './Pages/Furniture_Display';
import Error from './Pages/Error_404';
import Products from './Pages/Products';
import StaticPages from './Components/Static_Pages';
import Footer from './Components/Footer';



function App() {
  
  useEffect(() => {
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', icon);
  }, []);
  
  
  const animation = useLocation()

  return (
    <div className="Container_Body">
      <Navbar/>

      <AnimatePresence>
        <Routes location={animation} key={animation.pathname}>
          
          <Route path='/*' element={<Home/>}/>
          <Route path='*' element={<Error/>}/>
          <Route path='/Furniture/'>

            <Route path='*' element={<Home/>}/>
            <Route path=':id' element={<FurnitureDisplay/>}/>
          </Route>
          
            {/* Bedroon Nested Routes */}
            <Route path='Headboard/Furniture/:id' element={<FurnitureDisplay/>}/>
            <Route path='Dressing/Furniture/:id' element={<FurnitureDisplay/>}/>
            <Route path='Wardrobes/Furniture/:id' element={<FurnitureDisplay/>}/>
            <Route path='BedOther/Furniture/:id' element={<FurnitureDisplay/>}/>

        </Routes>
      </AnimatePresence>

      <Products/>
      <StaticPages/>
      <Footer/>
    </div>
  );
}

export default App;
