import '../Pages_Style/Contact.css';
import '../Components_Style/Page_Header.css';
import NavContact from '../Components_Images/NAV/Contact.png';
import FacebookIMG from '../Components_Images/LOGO/FB.png';
import InstagramIMG from '../Components_Images/LOGO/IG.png';
import ContactCard from '../Components/Internal/Contact_Card';
import ContactCardsData from '../Components/Internal/Contact_Data';
// import Newsletter from '../Components/Newsletter';

export default function Contact() {

  const contactElements = ContactCardsData.map(contactCard => {
    return <ContactCard 
        key={contactCard.id}
        {...contactCard}
    />
}) 

  return (
   <div className='Container_Contact'>

    <div id='NavContact' className='Container_PageSplitter pageHeaderContact'>

      <section className='Splitter_Active_PageLogo PageSplitter'>
        <img src={NavContact} alt='' width={45}/>
      </section>

      <section className='PageSplitter_Navbar'>
        <h1>Contact</h1>
      </section>
      
      <section className='Social_Media'>
        <img src={FacebookIMG} alt='Facebook'/>
        <img src={InstagramIMG} alt='Instagram'/>
      </section>

    </div>

    <div className='Container_Active_Contacts'>

    <div className='Container_Contact_Box'>

            <h2> Get in contact with one of our Sales individuals</h2>

        <div className='Contact_Box'>

            <section className='Contact_Display'>
                {contactElements[0]}
            </section>
    
            <section className='Contact_Display'>
                {contactElements[1]}
            </section>
    
            <section className='Contact_Display'>
                {contactElements[2]}
            </section>

        </div>

            <br/><h3>---- Scroll ----</h3>
    </div>

    {/* <Newsletter/> */}

   </div>

   

   </div>
  );
}
