import React from "react";
import '../../Components_Style/Internal/Contact_Card_2.css';
//import TestIMG from '../../Components_Images/Contact/DerangedMotion.png';



export default function ContactCard2(contactProps) {

   /*  {
      Create Badge to show who is active at what time/ days
  
    let badgeText 
    if (productProps.soldOut === 0) {
      badgeText = "Sold Out !!"
    } else if (productProps.specialOffer === true) {
      badgeText = "Active"
    }*/

    // function Mailto({ email, subject, body, ...props }) {
    //   return (
    //     <a href={`mailto:${email}?subject=${subject || ""}`}>
    //       {props.children}
    //     </a>
    //   );
    // }

  return (
   <div className='Container_Contact2_Card'>
    {/*
        {badgeText && <div className="Region_Badge">{badgeText}</div>}
    */}
      <div className='Contact2_Content'>
        <h3 id='' className='Text'>{contactProps.name}</h3>
        <center>
          <p>Cell: </p>
        <div className='Contact2Button'>
          <a id='' href={"tel:"+contactProps.cell} className='Text'>
            {contactProps.cell}
          </a>
        </div>
        </center>
        <h4 className='Text'>
            <p>Email: </p>
        <div className='Contact2Button'>
          <a className='Text' href={"mailto:"+contactProps.email}>
            {contactProps.email}
          </a>
        </div>
        </h4>
        <p>
        {contactProps.workDays}<br/>
        {contactProps.workHours}<br/><br/>
        {contactProps.workDays2}<br/>
        {contactProps.workHours2}
        </p>

      </div>
   </div>
  );
}
