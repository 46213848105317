import '../Pages_Style/Error_404.css';
import '../Components_Style/Page_Header.css';
import OnSpecial from '../Components/On_Special/Specials_Combo';
import DM from '../Components_Images/LOGO/DerangedMotion.png';
import { motion } from 'framer-motion';

export default function Error() {
  return (
   <motion.div className='Container_Error_404_Page'
   initial={{widths: 0}}
   animate={{width: '100%'}}
   exit={{opacity : 0}}
   >

    <div id='*' className='Container_PageSplitter pageHeaderError'>

      <section className='Splitter_Active_PageLogo PageSplitter'>
        <img src={DM} alt='' width={45}/>
      </section>

      <section className='PageSplitter_Navbar'>
        <h1>Failed to load</h1>
      </section>

    </div>

    <div className='Container_Error'>
      
    <div className='Error_Content'>
        <section>
            <h1>404</h1>
        </section>
        <section>
            <img src={DM} alt='' width={200}/>
        </section>
        <section>
            <h2>Page Not Found</h2>
        </section>
    </div>

    </div>

    <OnSpecial/>

   </motion.div>
  );
}
