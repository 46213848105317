import '../Pages_Style/About.css';
import '../Components_Style/Page_Header.css';
import NavAbout from '../Components_Images/NAV/Info.png';
import AboutIMG_1 from '../Components_Images/About/process_1.jpg';
import AboutIMG_2 from '../Components_Images/About/process_2.jpg';
import AboutIMG_3 from '../Components_Images/About/process_3.jpg';
import AboutIMG_4 from '../Components_Images/About/process_4.jpg';
import AboutIMG_5 from '../Components_Images/About/process_5.jpg';
import AboutIMG_6 from '../Components_Images/About/process_6.jpg';

export default function Info() {
  return (
   <div className='Container_About' id='NavAbout'>

    <div className='Container_PageSplitter pageHeaderAbout'>

      <section className='Splitter_Active_PageLogo PageSplitter'>
        <img src={NavAbout} alt='' width={45}/>
      </section>

      <section className='PageSplitter_Navbar'>
        <h1>About us</h1>
      </section>

    </div>

    <div className='Container_Information'>

      <section>
        <h1>Who Are We?</h1>
        {/* add image of our team here*/}
        <p>
          We are an online Furniture Retailer based in South Africa currently active in & around Gauteng. We works in collaboration with Local Furniture Manufacturers & Stores. we provide our products & services to the surrounding communities of South Africa in order to premote growth within our country.
                    <br/>
          FurnitureDeranged.com is a new online furniture retailer that has been formed to allow smaller local stores and localized manufacturers an opertunity to expand their market reach, as well as make furniture sales more accessible to our local communities.
        </p>
      </section>

      <section>
        <h2>Our Mission</h2>
        <p>
        The goal FurnitureDeranged.com is to expand the furniture industry in South Africa in order to provide more oppertunities & training in skilled labour in the industry. During this process we can build more luxury furniture as well as make the process of acquiring furniture more accessible to the general public.
        </p>
      </section>

      <section>
        <h2>Our Products</h2>
        <p>
          Our Clients turn raw materials into the luxury furniture our Customers can enjoy. ;)
        </p>

        <img src={AboutIMG_1} alt='Failed Loading'/>
        <img src={AboutIMG_2} alt='Failed Loading'/>
        <img src={AboutIMG_3} alt='Failed Loading'/>
        <img src={AboutIMG_4} alt='Failed Loading'/>
        <img src={AboutIMG_5} alt='Failed Loading'/>
        <img src={AboutIMG_6} alt='Failed Loading'/>

        <p>
        We have an array of furniture that is Manufactured & a small array of limited stock that has been aquired through local stores. Most of our stock is produced & manufacturered in South Africa. Some of our stock is finite in quantity where as others are manufactured & can be ordered on request through our agents.
        </p>
      </section>

      <section>
        <h1>Looking To Sell on our Platform?</h1>
        <p>
        Are you a Manufacturer or local retailer based in South Africa looking to expand your market reach? Get in contact with one of our agents, our agent will provide you with the Operations Manager's Contact information where we can communicate further details as well as our Terms & Conditions. 
        </p>
      </section>
    </div>

   </div>
  );
}
