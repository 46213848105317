import '../Pages_Style/Furniture_Display.css';
import NavHome from '../Components_Images/NAV/Single_Product_Display.png';
import FurnitureDisplayCard from '../Components/Internal/Furniture_Display_Card';
import ProductCardsData from '../Components/Internal/Product_Data';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

const productElements = ProductCardsData.map(productCard => {
  return <FurnitureDisplayCard 
  key={productCard.id}
  group={productCard.group}
  {...productCard}
  />
}) 


export default function FurnitureDisplay(productProps) {
  let badgeText 
  if (productProps.soldOut === 0) {
    badgeText = "Sold Out !!"
  } else if (productProps.specialOffer === true) {
    badgeText = "Special"
  }
  
  const { id } = useParams();

  return (

  <motion.div className='Container_Product_Display_Page'
    initial={{opacity: 0}}
   animate={{opacity: 1}}
   exit={{opacity : 0}}
  >

    <div id='/' className='Container_PageSplitter pageHeaderhome'>

      <section className='Splitter_Active_PageLogo PageSplitter'>
        <img src={NavHome} alt='' width={45}/>
      </section>

      <section className='PageSplitter_Navbar'>
        <h1>On Display</h1>
      </section>

    </div>

    {productElements[id]}
  
  </motion.div>
  
  );
}
