import '../../Pages_Style/Products.css';
import '../../Components_Style/Page_Header.css';import React, { forwardRef, useEffect, useState} from 'react';
import LoungeTopSellers from '../../Components/Top_Sellers/Lounge_Top_Sellers';
import LoungeOnSpecial from '../../Components/On_Special/Lounge_Specials';
import Mirrors_Kists from './Pages_Categories/Other/Mirrors_Kists';
import Couches from './Pages_Categories/Lounge/Couches';
import Plasma_Units from './Pages_Categories/Lounge/Plasma_Units';
import CoffeeTables from './Pages_Categories/Lounge/CoffeeTables';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Lounge_Suites from './Pages_Categories/Lounge/Lounge_Suites';
import ComboDeals from './Pages_Categories/Other/Combo_Deals';
// import ProductCardsData from '../Components/Internal/Product_Data';
// import ProductCard from '../Components/Internal/Product_Card';

// const productElements = ProductCardsData.map(productCard => {
//     return <ProductCard 
//         key={productCard.id}
//         {...productCard}
//     />
// }) 

function LoungeSuites({},ref) {
    const [furnitureState , setFurnitureState] = useState('loungeSuites');
    
    const [fullSuiteState , setFullSuiteState] = useState(false)
    const [partialSuite_1State , setPartialSuite_1State] = useState(false)
    const [partialSuite_2State , setPartialSuite_2State] = useState(false)
    const [partialSuite_3State , setPartialSuite_3State] = useState(false)
    const [partialSuite_4State , setPartialSuite_4State] = useState(false)

    useEffect(() => {
        furnitureState === "loungeSuites" ? setFullSuiteState(true) : setFullSuiteState(false);
        furnitureState === "plasmaUnits" ? setPartialSuite_1State(true) : setPartialSuite_1State(false);
        furnitureState === "coffeeTables" ? setPartialSuite_2State(true) : setPartialSuite_2State(false);
        furnitureState === "couches" ? setPartialSuite_3State(true) : setPartialSuite_3State(false);
        furnitureState === "mirrorsKists" ? setPartialSuite_4State(true) : setPartialSuite_4State(false);
    }, [furnitureState]);

    return (
   <div id='NavProduct' className='Container_Products' ref={ref}>
    
    <div className='Top_Seller_Cards_Container'>
        <LoungeTopSellers/>
    </div>

    <div className='Container_Specials'>
        <LoungeOnSpecial/>
    </div>
    
            <center><h2 id='Sub_Categories'>Select Sub Catagory</h2></center>

    <div  className='Container_Sub_Categories_Options'>
            
        <div className='Sub_Categories_Options'>

            <div className='Selected'>
                
            </div>
            <div className='Select_Box'>
                <div className='Container_Options_Menu'>

                <AnchorLink href='#Sub_Categories'>

                    <select 
                    value={furnitureState}
                    onChange={(e) => {
                        const selectedFurniture = e.target.value;
                        setFurnitureState(selectedFurniture)
                    }}>
                        <option value='loungeSuites' label='Lounge Suites'>
                        
                        </option>
                        <option value='couches' label='Couches'>

                        </option>
                        <option value='plasmaUnits' label='Plasma Units'>
                        
                        </option>
                        <option value='coffeeTables' label='Coffee Tables'>
                        
                        </option>
                        <option value='mirrorsKists' label='Mirrors & Kists'>
                        
                        </option>
                        {/* <option value='bedOther' label='Other'>
                        
                    </option> */}
                    </select>
                    </AnchorLink>

                </div>
            </div>

        </div>

    </div>
    
    <div className='Container_Listed_Sub_Categories'>

        { fullSuiteState && <Lounge_Suites/> }
        { partialSuite_1State && <Plasma_Units/> }
        { partialSuite_3State && <Couches/> }
        { partialSuite_2State && <CoffeeTables/> }
        { partialSuite_4State && <Mirrors_Kists/>  }
        
        
    </div>
       

   </div>
  );
}

export default forwardRef(LoungeSuites)

   
   

       

//    </div>


