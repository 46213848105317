import '../../Components_Style/Internal/On_Special.css';
import ProductCard from '../Internal/Product_Card';
import ProductCardsData from '../Internal/Product_Data';

export default function BedroomOnSpecial() {

    const productElements = ProductCardsData.map(productCard => {
        return <ProductCard 
            key={productCard.id}
            {...productCard}
        />
    });

  return (
   <div className='Container_On_Special'>

    <div className='Container_Specials_Box'>

            <h1> BEDROOM SPECIALS</h1>
        <div className='Specials_Box'>

            <section className='Specials_Display'>
                {productElements[0]}
            </section>
    
            <section className='Specials_Display'>
                {productElements[2]}
            </section>
    
            <section className='Specials_Display'>
                {productElements[3]}
            </section>
    
            <section className='Specials_Display'>
                {productElements[4]}
            </section>
    
            <section className='Specials_Display'>
                {productElements[5]}
            </section>

        </div>

    </div>

   </div>
  );
}
