import '../Components_Style/Footer.css';
import Logo from '../Components_Images/LOGO/FSD_SV.png';
import Collaberations from '../Components_Images/LOGO/Logo.png';
import CassLogo from '../Components_Images/LOGO/Manufacturers/Cass_Logo.jpg';
import PhoenixPU from '../Components_Images/LOGO/Manufacturers/Phoenix_Logo.png';
import CalganLogo from '../Components_Images/LOGO/Manufacturers/Calgan_Logo.jpeg'

export default function Footer() {
  return (
    <footer className="Container_Footer">

      <section className='Container_Footer_Logo'>
        <a href='index.html'>
            <img src={Logo} alt='Loading Failed'/></a>
      </section>

    <section className='Container_Footer_Collaberations'>
        <h2>Working in Collaberations with:</h2>
        <img src={Collaberations} alt='DerangedFurniture Logo Failed'/>
        <img src={CassLogo} alt='Cass Furniture Logo Failed'/>
        <img src={PhoenixPU} alt='Phoenix PU Logo Failed'/>
        <img src={CalganLogo} alt='Calgan Recliner Logo Failed'/>
    </section>

    <section className='Container_Footer_Contributions'>
        <h2>Contribution to the artist. Logo Icon Assets used & edited </h2>
        <a href="https://www.freepik.com" title="Freepik">Freepik</a> 
        from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
    </section>

    </footer>
  );
}
