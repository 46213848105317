//import React from 'react';
//import ProductCard from './Product_Card';

export default [

    // Images not Working, return when Database Tutorial is completed
        {
            id: "1",
            img:require('../../Components_Images/Contact/Kev_Contact_Image.jpg') ,
            name: "Kevin De Klerk",
            occupation: "Sales Representative",
            cell: "068 576 3797",
            email: "kevindeklerk009@gmail.com",
            workDays: "Mon - Sat",
            workHours: "14h00 - 18h00",
            workDays2: "Sunday Whatsapp Only",
            workHours2: "12h00 - 16h00",
        },    

        {
            id: "2",
            img:require('../../Components_Images/Contact/DerangedMotion.png') ,
            name: "Comming Soon 1",
            occupation: "Sales",
            cell: "000 000 0000",
            email: "comming@soon.###.com"
        },

        {
            id: "3",
            img:require('../../Components_Images/Contact/DerangedMotion.png') ,
            name: "Comming Soon 2",
            occupation: "Sales",
            cell: "000 000 0000",
            email: "comming@soon.###.com"
    }

        
]