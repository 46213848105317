//import React from 'react';

export default [

    // ------------------------ Bedroom Suites Listing Starts Here-----------------------
        {
            id: "0",
            group: "Bedroom",
            name: "Palazzo 3 Piece Bedroom Suite",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_02.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "PPUzac",
            color: "Imbuia as Shown Above / Black Cherry / Cream Marble / Ironwood",
            priceRand: "R10 089",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R12 099",
            oldOfferCents: ".99",
            size: "H&P- H: 1 940 W: 2 560 D: 490, Dresser- H: 1 925 W: 1 700 D: 490",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },    

        {
            id: "1",
            group: "Bedroom",
            name: "Elegance 2 Piece Bedroom Suite",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_02.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "PPUabc",
            color: "Imbuia & Silver as Shown Above / Ironwood / Black Cherry / Cream Marble",
            priceRand: "R10 449",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 555 W: 2 370 D: 475, Dresser- H: 1 925 W: 1 700 D: 475",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "2",
            group: "Bedroom",
            name: "Sovereign 2 Piece Bedroom Suite",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "PPUbbc",
            color: "Black Cherry as Shown Above / Cream Marble / Ironwood / Imbuia",
            priceRand: "R9 805",
            priceCents: ".49",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R10 849",
            oldOfferCents: ".99",
            size: "H&P- H: 1 960 W: 2 435 D: 445, Dresser- H: 1 970 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "3",
            group: "Bedroom",
            name: "Monarch 3 Piece Bedroom Suite",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "PPUcbc",
            color: "Ironwood as Shown Above / Imbuia / Black Cherry / Cream Marble",
            priceRand: "R9 584",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R10 649",
            oldOfferCents: ".99",
            size: "H&P- H: 2 015 W: 2 575 D: 445, Dresser- H: 1 950 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "4",
            group: "Bedroom",
            name: "Da Vinci 3 Piece Bedroom Suite",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "PPUdbc",
            color: "Imbuia as Shown Above / Ironwood / Black Cherry / Cream Marble",
            priceRand: "R8 009",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R8 899",
            oldOfferCents: ".99",
            size: "H&P- H: 1 565 W: 2 370 D: 445, Dresser- H: 1 940 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "5",
            group: "Bedroom",
            name: "Rose 3 Piece Bedroom Suite",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_02.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "PPUebc",
            color: "Blach Cherry as Shown Above / Imbuia / Cream Marble / Ironwood",
            priceRand: "R8 414",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R9 349",
            oldOfferCents: ".99",
            size: "H&P- H: 1 775 W: 2 480 D: 445, Dresser- H: 1 805 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "6",
            group: "Bedroom",
            name: "Protea 2 Piece Bedroom Suite",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "PPUfbc",
            color: "Imbuia as Shown Above / Ironwood / Cream Cherry / Black Cherry",
            priceRand: "R9 199",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 775 W: 2 440 D: 445, Dresser- H: 1 805 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "7",
            group: "Bedroom",
            name: "Jaydee 3 Piece bedroom Suite",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "PPUgbc",
            color: "Imbuia as Shown Above / Cream Marblr / Black Cherry",
            priceRand: "R9 299",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 675 W: 2 530 D: 445, Dresser- H: 1 810 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "8",
            group: "Bedroom",
            name: "Mandy 3 Piece Bedroom Suite",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "PPUhbc",
            color: "Cream Marble as Shown Above / Imbuia / Ironwood / Black Cherry",
            priceRand: "R8 799",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 530 W: 2 350 D: 445, Dresser- H: 1 855 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "9",
            group: "Bedroom",
            name: "Princess 2 Piece Bedroom",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "PPUibc",
            color: "Pearl as Shown Above / Cream Marble / Black Cherry / Imbuia",
            priceRand: "R8 549",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 760 W: 2 100 D: 420, Dresser- H: 1 980 W: 1 640 D: 420",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "10",
            group: "Bedroom",
            name: "Palazzo Dresser",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_02.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_02.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_02.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_02.jpg') ,
            code: "PPUjbc",
            color: "Ironwood ss Shown Above / Imbuia / Cream Cherry / Black Marble",
            priceRand: "R6 499",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Dresser- H: 2 060 W: 1 700 D: 490",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "11",
            group: "Bedroom",
            name: "Elegance Dresser",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_02.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_02.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_02.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_02.jpg') ,
            code: "PPUkbc",
            color: "Imbuia as Shown Above / Ironwood / Cream Cherry / Black Cherry",
            priceRand: "R5 699",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Dresser- H: 1 925 W: 1 700 D: 475",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "12",
            group: "Bedroom",
            name: "Sovereign Dresser",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_02.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_02.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_02.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_02.png') ,
            code: "PPUlbc",
            color: "Black Cherry as Shown Above / Imbuia / Ironwood / Cream Marble",
            priceRand: "R5 199",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 252,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Dresser- H: 1 970 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "13",
            group: "Bedroom",
            name: "Monarch Dresser",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_02.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_02.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_02.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_02.png') ,
            code: "PPUmbc",
            color: "Ironwood as Shown Above / Imbuia / Cream Marble / Cream Cherry",
            priceRand: "R5 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Dresser- H: 1 950 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "14",
            group: "Bedroom",
            name: "Da Vinci Dresser",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_02.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_02.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_02.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_02.png') ,
            code: "PPUnbc",
            color: "Ironwood as Shown Above / Imbuia / Cream Marble / Black Cherry",
            priceRand: "R5 249",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Dresser- H: 1 940 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "15",
            group: "Bedroom",
            name: "Rose Dresser",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_02.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_02.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_02.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_02.jpg') ,
            code: "PPUobc",
            color: "Black Cherry as Shown Above / Cream Marble / Imbuia / Ironwood",
            priceRand: "R5 499",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Dresser- H: 1 805 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "16",
            group: "Bedroom",
            name: "Protea Dresser",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_02.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_02.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_02.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_02.png') ,
            code: "PPUpbc",
            color: "Ironwood as Shown Above / Imbuia / Cream Marble / Black Cherry",
            priceRand: "R5 449",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Dresser- H: 1 805 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "17",
            group: "Bedroom",
            name: "Jaydee Dresser",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_02.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_02.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_02.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_02.png') ,
            code: "PPUqbc",
            color: "Ironwood as Shown Above / Imbuia / Cream Marble / Black Cherry",
            priceRand: "R5 399",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Dresser- H: 1 810 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "18",
            group: "Bedroom",
            name: "Mandy Dresser",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_02.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_02.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_02.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_02.png') ,
            code: "PPUrbc",
            color: "Cream Marble as Shown Above / Black Cherry / Imbuia / Ironwood",
            priceRand: "R4 649",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Dresser- H: 1 855 W: 1 700 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "19",
            group: "Bedroom",
            name: "Princess Dresser",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_02.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_02.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_02.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_02.png') ,
            code: "PPUsbc",
            color: "Pearl as Shown Above / Cream Marble / Black Cherry / Imbuia",
            priceRand: "R5 599",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Dresser- H: 1 910 W: 1 640 D: 420",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "20",
            group: "Bedroom",
            name: "Palazzo Headboard & Pedestals",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_03.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Palazzo/Palazzo_03.png') ,
            code: "PPUtbc",
            color: "Ironwood as Shown Above / Imbuia / Cream Marble / Black Cherry",
            priceRand: "R6 299",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 760 W: 2 100 D: 420",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },    

        {
            id: "21",
            group: "Bedroom",
            name: "Elegance Headboard & Pedestals",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_03.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Elegance/Elegance_03.png') ,
            code: "PPUubc",
            color: "Imbuia as Shown Above / Ironwood / Cream Marble / Black Cherry",
            priceRand: "R5 799",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 555 W: 2 370 D: 475",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "22",
            group: "Bedroom",
            name: "Sovereign Headboard & Pedestals",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_03.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Sovereign/Sovereign_03.png') ,
            code: "PPUvbc",
            color: "Cream Marble as Shown Above / Black Cherry / Imbuia / Ironwood",
            priceRand: "R6 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 960 W: 2 435 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "23",
            group: "Bedroom",
            name: "Monarch Headboaed & Pedestals",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_03.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Monarch/Monarch_03.png') ,
            code: "PPUwbc",
            color: "Ironwood as Shown Above / Imbuia . Cream Marble / Black Cherry",
            priceRand: "R5 399",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 2 015 W: 2 575 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "24",
            group: "Bedroom",
            name: "Da Vinci Headboard & Pedestals",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_03.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Da_Vinci/Da_Vinci_03.png') ,
            code: "PPUxbc",
            color: "Ironwood as Shown Above / Imbuia / Cream Marble / Black Cherry",
            priceRand: "R5 199",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 565 W: 2 370 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "25",
            group: "Bedroom",
            name: "Rose Headboard & Pedestals",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_03.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Rose/Rose_03.png') ,
            code: "PPUybc",
            color: "Black Cherry as Shown Above / Cream Marble / Ironwood / Imbuia",
            priceRand: "R4 499",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 775 W: 2 440 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "26",
            group: "Bedroom",
            name: "Protea Headboard & Pedestals",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_03.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Protea/Protea_03.png') ,
            code: "PPUzbc",
            color: "Ironwood as Shown Above / Imbuia / Cream Marble / Black Cherry",
            priceRand: "R4 349",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 775 W: 2 440 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "27",
            group: "Bedroom",
            name: "Mandy Headboard & Pedestals",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_03.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Mandy/Mandy_03.png') ,
            code: "PPUacc",
            color: "Cream Marble as Shown Above / Black Cherry / Imbuia / Ironwood",
            priceRand: "R4 699",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 530 W: 2 350 D: 445",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "28",
            group: "Bedroom",
            name: "Princess Headboard & Pedestals",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_03.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Princess/Princess_03.png') ,
            code: "PPUbcc",
            color: "Pearl as Shown Above / Cream Marble / Black Cherry / Imbuia",
            priceRand: "R3 849",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 760 W: 2 100 D: 420",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "29",
            group: "Bedroom",
            name: "Jaydee Headboard & Pedestals",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_03.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Bedroom/Jaydee/Jaydee_03.png') ,
            code: "PPUmcc",
            color: "Ironwood as Shown Above / Imbuia / Cream Marble / Black Cherry",
            priceRand: "R4 549",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H&P- H: 1 760 W: 2 100 D: 420",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "30",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "31",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "32",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "33",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "34",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "35",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "36",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "37",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "38",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "39",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "40",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaa",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },    

        {
            id: "41",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "42",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "43",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "44",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "45",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "46",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "47",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "48",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "49",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "50",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "51",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "52",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "53",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "54",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "55",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "56",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "57",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "58",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "59",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "60",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaa",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },    

        {
            id: "61",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "62",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "63",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "64",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "65",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "66",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "67",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "68",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "69",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "70",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "71",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "72",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "73",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "74",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "75",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "76",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "77",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "78",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "79",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "80",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaa",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },    

        {
            id: "81",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "82",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "83",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "84",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "85",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "86",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "87",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "88",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "89",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "90",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "91",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "92",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "93",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "94",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "95",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaf",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "96",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaab",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "97",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaac",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "98",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaad",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "99",
            group: "Bedroom",
            name: "Bedroom Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaae",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },


        //------------------------ Dining Suites Listing Starts Here-----------------------

        {
            id: "100",
            group: "Dining",
            name: "Bali Dining Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Bali_DT/Bali_DT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Bali_DT/Bali_DT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Bali_DT/Bali_DT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Bali_DT/Bali_DT_01.jpg') ,
            code: "CFbab",
            color: "As Shown Above",
            priceRand: "R8 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 760  W: 1 300  L: 2 530",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "101",
            group: "Dining",
            name: "Gucci Double Leg Dining Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Gucci_DL_DT/Gucci_DL_DT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Gucci_DL_DT/Gucci_DL_DT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Gucci_DL_DT/Gucci_DL_DT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Gucci_DL_DT/Gucci_DL_DT_01.jpg') ,
            code: "CFfab",
            color: "As Shown Above",
            priceRand: "R7 019",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R7 799",
            oldOfferCents: ".99",
            size: "H: 760  W: 1 200  L: 2 200",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "102",
            group: "Dining",
            name: "Gucci Single Leg Dining Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Gucci_SL_DT/Gucci_SL_DT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Gucci_SL_DT/Gucci_SL_DT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Gucci_SL_DT/Gucci_SL_DT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Gucci_SL_DT/Gucci_SL_DT_01.jpg') ,
            code: "CFgab",
            color: "As Shown Above",
            priceRand: "R7 799",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 760  W: 1 200  L: 2 200",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "103",
            group: "Dining",
            name: "Javari Dining Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Javari_DT/Javari_DT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Javari_DT/Javari_DT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Javari_DT/Javari_DT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Javari_DT/Javari_DT_01.jpg') ,
            code: "CFjab",
            color: "As Shown Above",
            priceRand: "R10 799",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R11 999",
            oldOfferCents: ".99",
            size: "Not Yet Added",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "104",
            group: "Dining",
            name: "Ndlovu Dining Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Ndlovu_DT/Ndlovu_DT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Ndlovu_DT/Ndlovu_DT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Ndlovu_DT/Ndlovu_DT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Ndlovu_DT/Ndlovu_DT_01.jpg') ,
            code: "CFtab",
            color: "As Shown Above",
            priceRand: "R8 249",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 760  W: 1 200  L: 2 200",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "105",
            group: "Dining",
            name: "Tusker Rectangle Dining Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Tusker_Rect_DT/Tusker_Rect_DT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Tusker_Rect_DT/Tusker_Rect_DT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Tusker_Rect_DT/Tusker_Rect_DT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Tusker_Rect_DT/Tusker_Rect_DT_01.jpg') ,
            code: "CFobb",
            color: "As Shown Above",
            priceRand: "R13 449",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 750  W: 1 200  L: 2 400",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "106",
            group: "Dining",
            name: "Tusker Round Dining Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Tusker_Round_DT/Tusker_Round_DT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Tusker_Round_DT/Tusker_Round_DT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Tusker_Round_DT/Tusker_Round_DT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Dining_Tables/Tusker_Round_DT/Tusker_Round_DT_01.jpg') ,
            code: "CFpbb",
            color: "As Shown Above",
            priceRand: "R11 699",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 770  W: 1 600 L: 1 600",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "107",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "108",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "109",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "110",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "111",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "112",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "113",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "114",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "115",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "116",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "117",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "118",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "119",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "120",
            group: "Dining",
            name: "Javari Console Server",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Javari_Console_Server/Javari_Console_Server_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Javari_Console_Server/Javari_Console_Server_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Javari_Console_Server/Javari_Console_Server_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Javari_Console_Server/Javari_Console_Server_01.jpg') ,
            code: "CFhab",
            color: "As Shown Above",
            priceRand: "R5 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Not Added",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "121",
            group: "Dining",
            name: "Leopard Server",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Leopard_Server/Leopard_Server_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Leopard_Server/Leopard_Server_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Leopard_Server/Leopard_Server_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Leopard_Server/Leopard_Server_01.jpg') ,
            code: "CFnab",
            color: "As Shown Above",
            priceRand: "R6 349",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 800  W: 500  L: 1 800",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "122",
            group: "Dining",
            name: "Ndlovu Server",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Ndlovu_Server/Ndlovu_Server_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Ndlovu_Server/Ndlovu_Server_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Ndlovu_Server/Ndlovu_Server_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Ndlovu_Server/Ndlovu_Server_01.jpg') ,
            code: "CFuab",
            color: "As Shown Above",
            priceRand: "R5 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Not Yet Added",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "123",
            group: "Dining",
            name: "Satao Server",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Satao_Server/Satao_Server_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Satao_Server/Satao_Server_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Satao_Server/Satao_Server_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Satao_Server/Satao_Server_01.jpg') ,
            code: "CFgbb",
            color: "As Shown Above",
            priceRand: "R6 049",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 800  W: 500  L: 1 800",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "124",
            group: "Dining",
            name: "Savuti Server",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Savuti_Server/Savuti_Server_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Savuti_Server/Savuti_Server_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Savuti_Server/Savuti_Server_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Servers/Savuti_Server/Savuti_Server_01.jpg') ,
            code: "CFkbb",
            color: "As Shown Above",
            priceRand: "R6 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 800  W: 500  L: 1 800",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "125",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "126",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "127",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "128",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "129",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "130",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "131",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "132",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "33",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "134",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "135",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "136",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "137",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "138",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "139",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "140",
            group: "Dining",
            name: "Dining Suite Comming Soon",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaag",
            color: "As Shown Above",
            priceRand: "R5 595",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 68,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "141",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "142",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "143",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "144",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "145",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "146",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "147",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "148",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "149",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "150",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "151",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "152",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "153",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "154",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "155",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "156",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "157",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "158",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "159",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "160",
            group: "Dining",
            name: "Dining Suite Comming Soon",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaag",
            color: "As Shown Above",
            priceRand: "R5 595",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 68,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "161",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "162",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "163",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "164",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "165",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "166",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "167",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "168",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "169",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "170",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "171",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "172",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "173",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "174",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "175",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "176",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "177",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "178",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "179",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "180",
            group: "Dining",
            name: "Dining Suite Comming Soon",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaag",
            color: "As Shown Above",
            priceRand: "R5 595",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 68,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "181",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "182",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "183",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "184",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "185",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "186",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "187",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "188",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "189",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "190",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "191",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "192",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "193",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "194",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "195",
            group: "Dining",
            name: "Dining Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaal",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "196",
            group: "Dining",
            name: "Dining Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaah",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "197",
            group: "Dining",
            name: "Dining Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaai",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "198",
            group: "Dining",
            name: "Dining Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaj",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "199",
            group: "Dining",
            name: "Dining Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaak",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        

        //--------------------- Lounge Suites Listings Starts Here -------------------------------

        {
            id: "200",
            group: "Lounge",
            name: "Lyla Single Chair Fabric",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Fabric/Single_Mocha.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Fabric/Single_Mocha.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Fabric/Single_F_Group.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Fabric/Single_Java.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Fabric/Single_Americano.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Fabric/Single_Espresso.png') ,
            code: "CLaad / cad",
            color: "Mocha / Java / Espresso or Americano",
            type: "Recliner",
            priceRand: "R4 549",
            priceCents: ".99",
            multiOptions: true,
            type2: "Motorized",
            priceRand2: "R6 249",
            priceCents2: ".99",
            type3: "Static",
            priceRand3: "R3 349",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 000  D: 950  W: 1000",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "201",
            group: "Lounge",
            name: "Lyla Single Chair",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Leather/Single_Brown.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Leather/Single_Brown.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Leather/Single_L_Group.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Leather/Single_Black.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Leather/Single_Red.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Leather/Single_Mushroom.png') ,
            code: "CLbab",
            color: "Brown / Red / Mushroom or Black",
            type: "Leather Look",
            priceRand: "R4 549",
            priceCents: ".99",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R6 099",
            priceCents2: ".99",
            type3: "Full Leather",
            priceRand3: "R10 269",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1000  D: 950  W: 1000",
            description: "Static Options also available at a lower price.  Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "202",
            group: "Lounge",
            name: "Lyla Motorized Chair",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Single_Group.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Single_Group.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Leather/Single_Brown.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Fabric/Single_Mocha.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Leather/Single_L_Group.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Fabric/Single_F_Group.png') ,
            code: "CLdad",
            color: "Brown / Red / Mushroom or Black",
            type: "Fabric & Leather Look",
            priceRand: "R6 249",
            priceCents: ".99",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R8 149",
            priceCents2: ".99",
            type3: "Full Leather",
            priceRand3: "R12 149",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 000  D: 950 W: 1 000",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "203",
            group: "Lounge",
            name: "Lyla Rocker Chair in Fabric & Leathers",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Single_Group.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Single_Group.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Leather/Single_Brown.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Fabric/Single_Mocha.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Leather/Single_L_Group.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Single_Couch/Fabric/Single_F_Group.png') ,
            code: "CLead / fad",
            color: "Fabric: Java / Americano / Mocha or Espresso | Leathers: Brown / Red / Mushroom or Black",
            type: "Fabric & Leather Look",
            priceRand: "R5 999",
            priceCents: ".99",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 449",
            priceCents2: ".99",
            type3: "Full Leather",
            priceRand3: "R11 549",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 000  D: 950  W: 1 000",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "204",
            group: "Lounge",
            name: "Lyla 2 Division Fabric Couch",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Fabric/Double_Mocha.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Fabric/Double_Mocha.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Fabric/Double_F_Group.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Fabric/Double_Java.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Fabric/Double_Espresso.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Fabric/Double_Americano.png') ,
            code: "CLgad",
            color: "Americano / Mocha / Java or Espresso",
            type: "Recliner",
            priceRand: "R6 829",
            priceCents: ".99",
            multiOptions: true,
            type2: "Static",
            priceRand2: "R5 849",
            priceCents2: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R7 559",
            oldOfferCents: ".99",
            size: "H: 1 050  D: 930  W: 1660",
            description: "*Consoles can be added seperately*  Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "205",
            group: "Lounge",
            name: "Lyla 2 Devision Couch",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Leather/Double_Brown.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Leather/Double_Brown.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Leather/Double_L_Group.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Leather/Double_Black.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Leather/Double_Red.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Double_Couch/Leather/Double_Mushroom.png') ,
            code: "CLhad",
            color: "Red / Brown / Black / Mushroom",
            type: "Half Leather",
            priceRand: "R8 954",
            priceCents: ".99",
            multiOptions: true,
            type2: "Leather Look",
            priceRand2: "R7 559",
            priceCents2: ".99",
            type3: "Full Leather",
            priceRand3: "R16 099",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R9 949",
            oldOfferCents: ".99",
            size: "H: 1 050  D: 930  W: 1660",
            description: "*Consoles can be added seperately* / Static Couches are available at reduced prices / Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "206",
            group: "Lounge",
            name: "Lyla 3 Devision Fabric Couch",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Fabric/3_Div_Java.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Fabric/3_Div_Java.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Fabric/3_Div_Mocha.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Fabric/3_Div_Americano.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Fabric/3_Div_Java.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Fabric/3_Div_Espresso.png') ,
            code: "CLiad",
            color: "Java / Americano / Mocha / Espresso",
            type: "Recliner",
            priceRand: "R10 049",
            priceCents: ".99",
            multiOptions: true,
            type2: "Static",
            priceRand2: "R8 299",
            priceCents2: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 050  D: 930  W: 2200",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "207",
            group: "Lounge",
            name: "Lyla 3 Devision Couch",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Leather/3_Div_Brown.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Leather/3_Div_Brown.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Leather/3_Div_Black.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Leather/3_Div_Mushroom.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Leather/3_Div_Brown.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/3_Div_Couch/Leather/3_Div_Red.png') ,
            code: "CLjad",
            color: "Brown / Red / Black / Mushroom",
            type: "Leather Look",
            priceRand: "R10 049",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R13 049",
            priceCents2: ".99",
            type3: "Full Leather",
            priceRand3: "R21 649",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 050  D: 930  W: 2200",
            description: "Static Couches are available at reduced prices / Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "208",
            group: "Lounge",
            name: "Lyla 4 Piece Fabric Chaise & Console",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Fabric/Chaise_Java.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Fabric/Chaise_Java.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Fabric/Chaise_F_Group.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Fabric/Chaise_Mocha.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Fabric/Chaise_Americano.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Fabric/Chaise_Espresso.png') ,
            code: "CLkad",
            color: "Java / Americano / Mocha / Espresso",
            type: "Fabric",
            priceRand: "R12 799",
            priceCents: ".99",
            multiOptions: true,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 050  D: 1 670  W: 2 650",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "209",
            group: "Lounge",
            name: "Lyla 4 Piece Chaise & Console",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Leather/Chaise_Brown.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Leather/Chaise_Brown.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Leather/Chaise_L_Group.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Leather/Chaise_Black.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Leather/Chaise_Red.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Chaise_Couch/Leather/Chaise_Mushroom.png') ,
            code: "CLlad",
            color: "Brown / Red / Black / Mushroom",
            type: "Leather Look",
            priceRand: "R12 799",
            priceCents: ".99",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R17 299",
            priceCents2: ".99",
            type3: "Full Leather",
            priceRand3: "R28 799",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 050  D: 1 670  W: 2 650",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep"
        },
        
        {
            id: "210",
            group: "Lounge",
            name: "Lyla 321 Suite Fabric Range",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Fabric/321_Americano.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Fabric/321_Americano.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Fabric/321_Java.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Fabric/321_Espresso.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Fabric/321_Mocha.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Fabric/321_Americano.png') ,
            code: "CLmad / oad / qad",
            color: "Americano / Mocha / Espresso / Java",
            type: "Static Suite",
            priceRand: "R17 499",
            priceCents: ".99",
            multiOptions: true,
            type2: "1 Recliner Chair",
            priceRand2: "R18 699",
            priceCents2: ".99",
            type3: "3 Recliners",
            priceRand3: "R20 399",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "***",
            description: "Consoles can be added seperately / Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "211",
            group: "Lounge",
            name: "Lyla 321 Suite Leather Look Range",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Black.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Black.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Brown.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Mushroom.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Red.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Black.png') ,
            code: "CLnad / pad / rad",
            color: "Black / Brown / Mushroom / Red",
            type: "Static Suite",
            priceRand: "R17 499",
            priceCents: ".99",
            multiOptions: true,
            type2: "1 Recliner Chair",
            priceRand2: "R18 699",
            priceCents2: ".99",
            type3: "3 Recliners",
            priceRand3: "R20 399",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "***",
            description: "Consoles can be added seperately / Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "212",
            group: "Lounge",
            name: "Lyla 321 Suite Half Leather Range",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Red.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Brown.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Black.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Mushroom.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Red.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Brown.png') ,
            code: "CLnad / pad / rad",
            color: "Americano / Mocha / Espresso / Java",
            type: "Static Suite",
            priceRand: "R20 399",
            priceCents: ".99",
            multiOptions: true,
            type2: "1 Recliner Chair",
            priceRand2: "R24 199",
            priceCents2: ".99",
            type3: "3 Recliners",
            priceRand3: "R26 649",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "***",
            description: "Consoles can be added seperately / Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "213",
            group: "Lounge",
            name: "Lyla 321 Suite Full Leather Range",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Brown.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Brown.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Black.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Mushroom.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Red.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Brown.png') ,
            code: "CLnad / pad / rad",
            color: "Americano / Mocha / Espresso / Java",
            type: "Static Suite",
            priceRand: "R39 299",
            priceCents: ".99",
            multiOptions: true,
            type2: "1 Recliner Chair",
            priceRand2: "R41 199",
            priceCents2: ".99",
            type3: "3 Recliners",
            priceRand3: "R44 549",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "***",
            description: "Consoles can be added seperately / Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "214",
            group: "Lounge",
            name: "Lyla 5 Action Full Recliner Suites with Console",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Brown.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Brown.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Fabric/321_Group.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Red.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Leather/321_Black.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/321_Suite/Fabric/321_Java.png') ,
            code: "FDsad / tad",
            color: "Leathers: Brown / Black / Red / Mushroom | Fabric: Mocha / Americano / Espresso / Java",
            type: "Fabrics & Leather Look",
            priceRand: "R24 399",
            priceCents: ".99",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R32 049",
            priceCents2: ".99",
            type3: "Full Leather",
            priceRand3: "R53 049",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "***",
            description: "Consoles included in the purchase / Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "215",
            group: "Lounge",
            name: "Lyla Corner Chaise Range in Fabrics & Leathers",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner_Chaise/Fabric/Corner_Chaise_Espresso.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner_Chaise/Leather/Corner_Chaise_Brown.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner_Chaise/Corner_Chaise_Group.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner_Chaise/Fabric/Corner_Chaise_F_Group.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner_Chaise/Leather/Corner_Chaise_L_Group.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner_Chaise/Fabric/Corner_Chaise_Espresso.png') ,
            code: "CLuad / vad",
            color: "Fabric: Java / Espresso / Americano / Mocha | Leather: Brown / Black / Red / Mushroom",
            type: "Fabric & Leather Look",
            priceRand: "R17 639",
            priceCents: ".99",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R26 649",
            priceCents2: ".99",
            type3: "Full Leather",
            priceRand3: "R44 449",
            priceCents3: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R19 599",
            oldOfferCents: ".99",
            size: "***",
            description: "Consoles included in the purchase / Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "216",
            group: "Lounge",
            name: "Lyla Corner Recliner in Fabric & Leathers",
            img:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner/Leather/Corner_Mushroom.png') ,
            img_1:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner/Leather/Corner_Mushroom.png') ,
            img_2:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner/Corner_Group.png') ,
            img_3:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner/Fabric/Corner_F_Group.png') ,
            img_4:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner/Leather/Corner_L_Group.png') ,
            img_5:require('../../Components_Images/Product_Listing/Calgan_Lounge_Catalogue/Lyla/Corner/Fabric/Corner_Java.png') ,
            code: "CLwad / xad",
            color: "Fabric: Java / Espresso / Americano / Mocha | Leather: Brown / Black / Red / Mushroom",
            type: "Fabric & Leather Look",
            priceRand: "R19 349",
            priceCents: ".99",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R25 449",
            priceCents2: ".99",
            type3: "Full Leather",
            priceRand3: "R42 749",
            priceCents3: ".",
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "***",
            description: "Consoles included in the purchase / Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "217",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "218",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "219",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "220",
            group: "Lounge",
            name: "Lounge Suite Comming Soon",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaam",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 999,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "221",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "222",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "223",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "224",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: true,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "225",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "226",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "227",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            type: "Leather Look",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            type2: "Half Leather",
            priceRand2: "R7 000",
            priceCents2: ".00",
            type3: "Full Leather",
            priceRand3: "R7 000",
            priceCents3: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "228",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: true,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "229",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: true,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "230",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: true,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "231",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: true,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "232",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: true,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "233",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: true,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "234",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: true,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "235",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "236",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "237",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "238",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "239",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "240",
            group: "Lounge",
            name: "Lounge Suite Comming Soon",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaam",
            color: "As Shown Above",
            priceRand: "R5 595",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 999,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "241",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "242",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "243",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "244",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "245",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "246",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "247",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "248",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "249",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "250",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "251",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "252",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "253",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "254",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "255",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "256",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "257",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "258",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "259",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "260",
            group: "Lounge",
            name: "Lounge Suite Comming Soon",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaam",
            color: "As Shown Above",
            priceRand: "R5 595",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 999,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "261",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "262",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "263",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "264",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "265",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "266",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "267",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "268",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "269",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "270",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "271",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "272",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "273",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "274",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "275",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "276",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "277",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "278",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "279",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "280",
            group: "Lounge",
            name: "Lounge Suite Comming Soon",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaam",
            color: "As Shown Above",
            priceRand: "R5 595",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 999,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "281",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "282",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "283",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "284",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "285",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "286",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "287",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "288",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "289",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "290",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "291",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "292",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "293",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "294",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "295",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "296",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "297",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "298",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "299",
            group: "Lounge",
            name: "Lounge Suite Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        //--------------------- Lounge Suites Listings Starts Here -------------------------------

        {
            id: "300",
            group: "Other",
            name: "Round Berry Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Round_Berry/Round_Berry_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Round_Berry/Round_Berry_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Round_Berry/Round_Berry_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Round_Berry/Round_Berry_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Round_Berry/Round_Berry_02.png') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Round_Berry/Round_Berry_01.png') ,
            code: "PPUaac",
            color: "Silver as Shown Above / White / Black / Gold / Pewter",
            priceRand: "R2 799",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Radius: 1 300",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "301",
            group: "Other",
            name: "Oval Berry Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Oval_Berry/Oval_Berry_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Oval_Berry/Oval_Berry_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Oval_Berry/Oval_Berry_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Oval_Berry/Oval_Berry_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Oval_Berry/Oval_Berry_02.png') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Oval_Berry/Oval_Berry_01.png') ,
            code: "PPUbac",
            color: "Gold as Shown Above / Silver / White / Black / Pewter",
            priceRand: "R2 499",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 380  W: 1 100",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "302",
            group: "Other",
            name: "Jumbo Princess Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Jumbo_Princess/Jumbo_Princess_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Jumbo_Princess/Jumbo_Princess_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Jumbo_Princess/Jumbo_Princess_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Jumbo_Princess/Jumbo_Princess_01.png') ,
            code: "PPUcac",
            color: "White as Shown Above / Black / Gold / Silver / Pewter",
            priceRand: "R2 249",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 880  W: 1 380",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "303",
            group: "Other",
            name: "Deco Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Deco/Deco_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Deco/Deco_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Deco/Deco_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Deco/Deco_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Deco/Deco_02.png') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Deco/Deco_01.png') ,
            code: "PPUdac",
            color: "White as Shown Above / Black / Silver / Gold / Pewter",
            priceRand: "R2 249",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 000  W: 910",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "304",
            group: "Other",
            name: "Acorn Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Acorn/Acorn_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Acorn/Acorn_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Acorn/Acorn_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Acorn/Acorn_01.png') ,
            code: "PPUeac",
            color: "White as Shown Above / Black / Gold / Silver / Pewter",
            priceRand: "R3 649",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 245  W: 1 545",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "305",
            group: "Other",
            name: "Edwardian 504 Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Edwardian_504/Edwardian_504_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Edwardian_504/Edwardian_504_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Edwardian_504/Edwardian_504_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Edwardian_504/Edwardian_504_01.png') ,
            code: "PPUfac",
            color: "Gold as Shown Above / Silver / White / Black / Pewter",
            priceRand: "R1 499",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 200  W: 900",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "306",
            group: "Other",
            name: "Gold Rush TVL Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Gold_Rush_TVL/Gold_Rush_TVL_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Gold_Rush_TVL/Gold_Rush_TVL_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Gold_Rush_TVL/Gold_Rush_TVL_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Gold_Rush_TVL/Gold_Rush_TVL_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Gold_Rush_TVL/Gold_Rush_TVL_02.png') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Gold_Rush_TVL/Gold_Rush_TVL_01.png') ,
            code: "PPUgac",
            color: "Gold as Shown Above / Silver / White / Black / Pewter",
            priceRand: "R1 399",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 005  W: 705",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "307",
            group: "Other",
            name: "Small Leaf Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Leaf_Small/Leaf_Small_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Leaf_Small/Leaf_Small_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Leaf_Small/Leaf_Small_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Leaf_Small/Leaf_Small_01.png') ,
            code: "PPUhac",
            color: "Black as Shown Above / White / Silver / Gold / Pewter",
            priceRand: "R1 399",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Radius: 815",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "308",
            group: "Other",
            name: "Twin Arch Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Twin_Arch/Twin_Arch_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Twin_Arch/Twin_Arch_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Twin_Arch/Twin_Arch_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Twin_Arch/Twin_Arch_01.png') ,
            code: "PPUiac",
            color: "Ironwood as Shown Above / Silver / Gold / Black / White / Pewter",
            priceRand: "R1 649",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 315  W: 665",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "309",
            group: "Other",
            name: "Small Rib Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Small_Rib/Small_Rib_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Small_Rib/Small_Rib_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Small_Rib/Small_Rib_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Small_Rib/Small_Rib_01.png') ,
            code: "PPUjac",
            color: "Silver as Shown Above / Gold / White / Black / Pewter",
            priceRand: "R1 299",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Radius: 1 070",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "310",
            group: "Other",
            name: "Rose Cheval Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Rose_Cheval/Rose_Cheval_03.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Rose_Cheval/Rose_Cheval_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Rose_Cheval/Rose_Cheval_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Rose_Cheval/Rose_Cheval_03.png') ,
            img_4:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Rose_Cheval/Rose_Cheval_02.png') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Rose_Cheval/Rose_Cheval_01.png') ,
            code: "PPUkac",
            color: "Silver as Shown Above / Gold / White / Black / Pewter",
            priceRand: "R1 699",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 520  W: 570",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "311",
            group: "Other",
            name: "K & K Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/K&K_Mirror/K&K_Mirror_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/K&K_Mirror/K&K_Mirror_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/K&K_Mirror/K&K_Mirror_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/K&K_Mirror/K&K_Mirror_01.png') ,
            code: "PPUlac",
            color: "Silver as Shown Above / Gold / White / Black / Pewter",
            priceRand: "R949",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 560  W: 540",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "312",
            group: "Other",
            name: "Small Deco Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Small_Deco/Small_Deco_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Small_Deco/Small_Deco_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Small_Deco/Small_Deco_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Small_Deco/Small_Deco_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Small_Deco/Small_Deco_02.png') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Small_Deco/Small_Deco_01.png') ,
            code: "PPUmac",
            color: "White as Shown Above / Black / Silver / Gold / Pewter",
            priceRand: "R1 249",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 520  W: 470",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "313",
            group: "Other",
            name: "Monarch Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Monarch/Monarch_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Monarch/Monarch_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Monarch/Monarch_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/Monarch/Monarch_01.png') ,
            code: "PPUnac",
            color: "Silver as Shown Above / Gold / White / Black / Pewter",
            priceRand: "R2 199",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 520  W: 960",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "314",
            group: "Other",
            name: "24 Mirror",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/24_Mirror/24_Mirror_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/24_Mirror/24_Mirror_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/24_Mirror/24_Mirror_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Mirrrors/24_Mirror/24_Mirror_01.png') ,
            code: "PPUoac",
            color: "Gold as Shown Above / Silver / White / Black / Pewter",
            priceRand: "R1 049",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 925  W: 625",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "315",
            group: "Other",
            name: "Large Elephant Kist",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Elephant/Elephant_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Elephant/Elephant_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Elephant/Elephant_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Elephant/Elephant_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Elephant/Elephant_02.png') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Elephant/Elephant_01.png') ,
            code: "PPUpac",
            color: "Imbuia as Shown Above / Ironwood as Shown Above / Cream Marble / Pearl",
            priceRand: "R3 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 755  W: 1 115  D: 615",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "316",
            group: "Other",
            name: "Small Budget Kist",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Budget/Budget_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Budget/Budget_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Budget/Budget_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Budget/Budget_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Budget/Budget_02.png') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Budget/Budget_01.png') ,
            code: "PPUqac",
            color: "Ironwood as Shown Above / Imbuia as Shown Above / Cream Marble / Pearl",
            priceRand: "R2 599",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 580  W: 915  D: 615",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "317",
            group: "Other",
            name: "Big 5 Compact Kist",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Big_5_Compact/Big_5_Compact_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Big_5_Compact/Big_5_Compact_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Big_5_Compact/Big_5_Compact_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Big_5_Compact/Big_5_Compact_01.png') ,
            code: "PPUrac",
            color: "Ironwood as Shown Above / Imbuia / Cream Marble / Pearl",
            priceRand: "R2 649",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 580  W: 915  D: 500",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "318",
            group: "Other",
            name: "Space Saver Kist",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Space_Saver/Space_Saver_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Space_Saver/Space_Saver_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Space_Saver/Space_Saver_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Kist/Space_Saver/Space_Saver_01.png') ,
            code: "PPUsac",
            color: "Ironwood as Shown Above / Imbuia / Cream Marble / Pearl",
            priceRand: "R2 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 1 045  W: 870  D: 520",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "319",
            group: "Other",
            name: "Halfmoon Table",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Halfmoon/Halfmoon_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Halfmoon/Halfmoon_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Halfmoon/Halfmoon_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Halfmoon/Halfmoon_01.png') ,
            code: "PPUtac",
            color: "Cream Marble & Gold as Shown Above / Imbuia & Gold",
            priceRand: "R1 899",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 750  W: 895  D: 480",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "320",
            group: "Other",
            name: "Side Table",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Side_Table/Side_Table_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Side_Table/Side_Table_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Side_Table/Side_Table_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Side_Table/Side_Table_01.png') ,
            code: "PPUuac",
            color: "Cream Marble & Gold as Shown Above / Imbuia & Gold",
            priceRand: "R1 549",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 545  D: 500",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "321",
            group: "Other",
            name: "Large Oval Coffee Table",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Large_Oval_Coffe_Table/Large_Oval_Coffee_Table_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Large_Oval_Coffe_Table/Large_Oval_Coffee_Table_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Large_Oval_Coffe_Table/Large_Oval_Coffee_Table_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Large_Oval_Coffe_Table/Large_Oval_Coffee_Table_01.png') ,
            code: "PPUvac",
            color: "Cream Marble & Gold as Shown Above / Imbuia & Gold",
            priceRand: "R2 549",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 545  W: 1 105  D: 660",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "322",
            group: "Other",
            name: "Carousel Coffee Table with Glass Top",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Carousel_Coffee_table/Carousel_Coffee_Table_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Carousel_Coffee_table/Carousel_Coffee_Table_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Carousel_Coffee_table/Carousel_Coffee_Table_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Carousel_Coffee_table/Carousel_Coffee_Table_01.png') ,
            code: "PPUwac",
            color: "Imbuia & Gold as Shown Above / Cream Marble & gold",
            priceRand: "R2 849",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 480  W: 935",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "323",
            group: "Other",
            name: "Diamond Coffee Table",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Diamond_Coffee_Table/Diamond_Coffee_Table_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Diamond_Coffee_Table/Diamond_Coffee_Table_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Diamond_Coffee_Table/Diamond_Coffee_Table_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Diamond_Coffee_Table/Diamond_Coffee_Table_01.png') ,
            code: "PPUxac",
            color: "Imbuia Marble as Shown Above / Cream Marble",
            priceRand: "R1 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 445  W: 900  D: 600",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "324",
            group: "Other",
            name: "Scroll Columns with Glass Top & 505 Mirror Combo",
            img:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Scroll_Column_Combo/Scroll_Column_01.png') ,
            img_1:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Scroll_Column_Combo/Scroll_Column_01.png') ,
            img_2:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Scroll_Column_Combo/Scroll_Column_02.png') ,
            img_3:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Scroll_Column_Combo/Scroll_Column_01.png') ,
            img_4:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Scroll_Column_Combo/Scroll_Column_02.png') ,
            img_5:require('../../Components_Images/Product_Listing/PU_Catalogue/Tables/Scroll_Column_Combo/Scroll_Column_01.png') ,
            code: "PPUyac",
            color: "Bone White & Gold as Shown Above / Ironwood & Gold / Gold",
            priceRand: "R4 099",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Mirror- H: 1 200  W: 1 200  D: 320  Column- H: 770  W: 1 200  D: 350",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "325",
            group: "Other",
            name: "Bali Coffee Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Bali_CT/Bali_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Bali_CT/Bali_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Bali_CT/Bali_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Bali_CT/Bali_CT_01.jpg') ,
            code: "CFaab",
            color: "As Shown Above",
            priceRand: "R5 299",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 550  W: 880  L: 1 300",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "326",
            group: "Other",
            name: "Javari Coffee Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Javari_CT/Javari_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Javari_CT/Javari_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Javari_CT/Javari_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Javari_CT/Javari_CT_01.jpg') ,
            code: "CFiab",
            color: "As Shown Above",
            priceRand: "R6 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Not Added",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "327",
            group: "Other",
            name: "Montroux Coffee Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Montroux_CT/Montroux_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Montroux_CT/Montroux_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Montroux_CT/Montroux_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Montroux_CT/Montroux_CT_01.jpg') ,
            code: "CFrab",
            color: "As Shown Above",
            priceRand: "R5 699",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 530  W: 850  L: 1 300",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "328",
            group: "Other",
            name: "Ndlovu 900 Coffee Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ndlovu_900_CT/Ndlovu_900_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ndlovu_900_CT/Ndlovu_900_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ndlovu_900_CT/Ndlovu_900_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ndlovu_900_CT/Ndlovu_900_CT_01.jpg') ,
            code: "CFsab",
            color: "As Shown Above",
            priceRand: "R6 149",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 530  W: 900  L: 900",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "329",
            group: "Other",
            name: "Prince Coffee Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Prince_CT/Prince_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Prince_CT/Prince_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Prince_CT/Prince_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Prince_CT/Prince_CT_01.jpg') ,
            code: "CFwab",
            color: "As Shown Above",
            priceRand: "R2 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 500  W: 600  L: 1 200",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "330",
            group: "Other",
            name: "Rex Coffee Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Rex_CT/Rex_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Rex_CT/Rex_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Rex_CT/Rex_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Rex_CT/Rex_CT_01.jpg') ,
            code: "CFyab",
            color: "As Shown Above",
            priceRand: "R5 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 440  W: 800  L: 1 300",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "331",
            group: "Other",
            name: "Ryan Full Drawer Coffee Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ryan_Full_Drawer_CT/Ryan_Full_Drawer_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ryan_Full_Drawer_CT/Ryan_Full_Drawer_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ryan_Full_Drawer_CT/Ryan_Full_Drawer_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ryan_Full_Drawer_CT/Ryan_Full_Drawer_CT_01.jpg') ,
            code: "CFabb",
            color: "As Shown Above",
            priceRand: "R4 149",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 440  W: 630  L: 1 200",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "332",
            group: "Other",
            name: "Ryan Half Drawer Coffee table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ryan_Half_Drawer_CT/Ryan_Half_Drawer_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ryan_Half_Drawer_CT/Ryan_Half_Drawer_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ryan_Half_Drawer_CT/Ryan_Half_Drawer_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Ryan_Half_Drawer_CT/Ryan_Half_Drawer_CT_01.jpg') ,
            code: "CFbbb",
            color: "As Shown Above",
            priceRand: "R4 149",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 500  W: 600  L: 1 200",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "333",
            group: "Other",
            name: "Satao Coffee Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Satao_CT/Satao_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Satao_CT/Satao_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Satao_CT/Satao_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Satao_CT/Satao_CT_01.jpg') ,
            code: "CFdbb",
            color: "As Shown Above",
            priceRand: "R6 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 500  W: 780  :L 1 500",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "334",
            group: "Other",
            name: "Satao Round Coffee Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Satao_Roung_CT/Satao_Round_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Satao_Roung_CT/Satao_Round_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Satao_Roung_CT/Satao_Round_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Satao_Roung_CT/Satao_Round_CT_01.jpg') ,
            code: "CFebb",
            color: "As Shown Above",
            priceRand: "R5 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Not Added",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "335",
            group: "Other",
            name: "Savuti Coffee Table",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Savuti_CT/Savuti_CT_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Savuti_CT/Savuti_CT_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Savuti_CT/Savuti_CT_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Coffee_Tables/Savuti_CT/Savuti_CT_01.jpg') ,
            code: "CFjbb",
            color: "As Shown Above",
            priceRand: "R6 449",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 460  W: 800  L: 1 600",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "336",
            group: "Other",
            name: "Bali Plasma Unit",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Bali_Plasma/Bali_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Bali_Plasma/Bali_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Bali_Plasma/Bali_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Bali_Plasma/Bali_Plasma_01.jpg') ,
            code: "CFcab",
            color: "As Shown Above",
            priceRand: "R5 499",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 680  W: 530  L: 1 870",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "337",
            group: "Other",
            name: "Javari Plasma Unit",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Javari_Plasma/Javari_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Javari_Plasma/Javari_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Javari_Plasma/Javari_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Javari_Plasma/Javari_Plasma_01.jpg') ,
            code: "CFkab",
            color: "As Shown Above",
            priceRand: "R6 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Not Added",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "338",
            group: "Other",
            name: "Leopard Plasma Units",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Leopard_Plasma/Leopard_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Leopard_Plasma/Leopard_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Leopard_Plasma/Leopard_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Leopard_Plasma/Leopard_Plasma_01.jpg') ,
            code: "CFmab",
            color: "As Shown Above",
            priceRand: "R6 349",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 620  W: 560  L: 1 850",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "339",
            group: "Other",
            name: "Libra Plasma Unit",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Libra_Plasma/Libra_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Libra_Plasma/Libra_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Libra_Plasma/Libra_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Libra_Plasma/Libra_Plasma_01.jpg') ,
            code: "CFoab",
            color: "As Shown Above",
            priceRand: "R4 799",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 650  W: 500  L: 1 800",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "340",
            group: "Other",
            name: "Luna Plasma Unit",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Luna_Plasma/Luna_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Luna_Plasma/Luna_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Luna_Plasma/Luna_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Luna_Plasma/Luna_Plasma_01.jpg') ,
            code: "CFpab",
            color: "As Shown Above",
            priceRand: "R5 349",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "Not Added",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "341",
            group: "Other",
            name: "Prince Plasma Unit",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Prince_Plasma/Prince_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Prince_Plasma/Prince_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Prince_Plasma/Prince_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Prince_Plasma/Prince_Plasma_01.jpg') ,
            code: "CFxab",
            color: "As Shown Above",
            priceRand: "R4 649",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 650  W: 500  L: 1 800",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "342",
            group: "Other",
            name: "Rex Plasma unit",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Rex_Plasma/Rex_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Rex_Plasma/Rex_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Rex_Plasma/Rex_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Rex_Plasma/Rex_Plasma_01.jpg') ,
            code: "CFzab",
            color: "As Shown Above",
            priceRand: "R4 399",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 630  W: 600  L: 1 800",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "343",
            group: "Other",
            name: "Ryan Plasma Unit",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Ryan_Plasma/Ryan_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Ryan_Plasma/Ryan_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Ryan_Plasma/Ryan_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Ryan_Plasma/Ryan_Plasma_01.jpg') ,
            code: "CFcbb",
            color: "As Shown Above",
            priceRand: "R4 799",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 650  W: 500  L: 1 800",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "344",
            group: "Other",
            name: "Satao Plasma Unit",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Satao_Plasma/Satao_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Satao_Plasma/Satao_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Satao_Plasma/Satao_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Satao_Plasma/Satao_Plasma_01.jpg') ,
            code: "CFfbb",
            color: "As Shown Above",
            priceRand: "R6 749",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H:590  W: 500  L: 2 100",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "345",
            group: "Other",
            name: "Tusker Plasma Unit Version 1",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Tusker_01_Plasma/Tusker_01_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Tusker_01_Plasma/Tusker_01_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Tusker_01_Plasma/Tusker_01_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Tusker_01_Plasma/Tusker_01_Plasma_01.jpg') ,
            code: "CFlbb",
            color: "As Shown Above",
            priceRand: "R6 649",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 630  W: 600  L: 1 800",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "346",
            group: "Other",
            name: "Tusker Plasma Unit Version 2",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Tusker_02_Plasma/Tusker_02_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Tusker_02_Plasma/Tusker_02_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Tusker_02_Plasma/Tusker_02_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Tusker_02_Plasma/Tusker_02_Plasma_01.jpg') ,
            code: "CFmbb",
            color: "As Shown Above",
            priceRand: "R6 649",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 630  W: 600  L: 1 800",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "347",
            group: "Other",
            name: "Valencia 1500mm Plasma Unit",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Valencia_1500_Plasma/Valencia_1500_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Valencia_1500_Plasma/Valencia_1500_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Valencia_1500_Plasma/Valencia_1500_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Valencia_1500_Plasma/Valencia_1500_Plasma_01.jpg') ,
            code: "CFrbb",
            color: "As Shown Above",
            priceRand: "R4 449",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 640  W: 500  L: 1 500",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "348",
            group: "Other",
            name: "Valencia 1800mm Plasma Unit",
            img:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Valencia_1800_Plasma/Valencia_1800_Plasma_01.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Valencia_1800_Plasma/Valencia_1800_Plasma_01.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Valencia_1800_Plasma/Valencia_1800_Plasma_01.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Cass_Catalogue/Plasma_Units/Valencia_1800_Plasma/Valencia_1800_Plasma_01.jpg') ,
            code: "CFsbb",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "H: 640  W: 500  L: 1 800",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },
        
        {
            id: "349",
            group: "Other",
            name: "Mirrors Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "350",
            group: "Other",
            name: "Mirrors Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "351",
            group: "Other",
            name: "Mirrors Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "352",
            group: "Other",
            name: "Mirrors Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "353",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "354",
            group: "Other",
            name: "Mirrors Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "355",
            group: "Other",
            name: "Mirrors Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "356",
            group: "Other",
            name: "Mirrors Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "357",
            group: "Other",
            name: "Mirrors Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "358",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "359",
            group: "Other",
            name: "Mirrors Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "360",
            group: "Other",
            name: "Mirrors Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaam",
            color: "As Shown Above",
            priceRand: "R5 595",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 999,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "361",
            group: "Other",
            name: "Mirrors Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "362",
            group: "Other",
            name: "Mirrors Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "363",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "364",
            group: "Other",
            name: "Mirrors Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "365",
            group: "Other",
            name: "Mirrors Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "366",
            group: "Other",
            name: "Mirrors Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "367",
            group: "Other",
            name: "Mirrors Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "368",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "369",
            group: "Other",
            name: "Mirrors Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "370",
            group: "Other",
            name: "Mirrors Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "371",
            group: "Other",
            name: "Mirrors Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "372",
            group: "Other",
            name: "Mirrors Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "373",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "374",
            group: "Other",
            name: "Mirrors Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "375",
            group: "Other",
            name: "Mirrors Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "376",
            group: "Other",
            name: "Mirrors Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "377",
            group: "Other",
            name: "Mirrors Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "378",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "379",
            group: "Other",
            name: "Mirrors Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "380",
            group: "Other",
            name: "Mirrors Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaam",
            color: "As Shown Above",
            priceRand: "R5 595",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 999,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "381",
            group: "Other",
            name: "Mirrors Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "382",
            group: "Other",
            name: "Mirrors Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "383",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "384",
            group: "Other",
            name: "Mirrors Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "385",
            group: "Other",
            name: "Mirrors Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "386",
            group: "Other",
            name: "Mirrors Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "387",
            group: "Other",
            name: "Mirrors Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "388",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "389",
            group: "Other",
            name: "Mirrors Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "390",
            group: "Other",
            name: "Mirrors Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "391",
            group: "Other",
            name: "Mirrors Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "392",
            group: "Other",
            name: "Mirrors Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "393",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "394",
            group: "Other",
            name: "Mirrors Comming Soon 4",
            img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R26 999",
            priceCents: ".95",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "395",
            group: "Other",
            name: "Mirrors Comming Soon 5",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaar",
            color: "As Shown Above",
            priceRand: "R15 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 50,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "396",
            group: "Other",
            name: "Mirrors Comming Soon 1",
            img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaan",
            color: "As Shown Above",
            priceRand: "R7 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "397",
            group: "Other",
            name: "Mirrors Comming Soon 2",
            img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaao",
            color: "As Shown Above",
            priceRand: "R12 000",
            priceCents: ".00",
            inProvinceDelivery: "R 800",
            soldOut: 5,
            specialOffer: false,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "398",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },
        
        {
            id: "399",
            group: "Other",
            name: "Comming Soon ",
            img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaaq",
            color: "As Shown Above",
            priceRand: "R****",
            priceCents: ".**",
            inProvinceDelivery: "R 800",
            soldOut: 0,
            specialOffer: false,
            oldOfferRand: "R",
            oldOfferCents: ".",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        // ---------------------- Combo Deal Listings Start here ------------------------

        {
            id: "400",
            group: "Combo",
            name: "Bali Full Set",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_CT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_DT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_LS_Group.jpg') ,
            code: "CFaak",
            color: "As Shown Above",
            priceRand: "R18 054",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R19 549",
            oldOfferCents: ".95",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "401",
            group: "Combo",
            name: "Javari Full Set",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_CT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_PU.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_DT.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_Server.jpg') ,
            code: "CFbak",
            color: "As Shown Above",
            priceRand: "R28 859",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R31 249",
            oldOfferCents: ".95",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "402",
            group: "Combo",
            name: "Leopard Server & Plasma unit Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Leopard_Pieces/Leopard_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Leopard_Pieces/Leopard_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Leopard_Pieces/Leopard_Server.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Leopard_Pieces/Leopard_PU.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Leopard_Pieces/Leopard_Server.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Leopard_Pieces/Leopard_PU.jpg') ,
            code: "CFcak",
            color: "As Shown Above",
            priceRand: "R11 729",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R12 699",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "403",
            group: "Combo",
            name: "Ndlovu Full Set",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_900_CT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_DT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_Server.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_DS_Group.jpg') ,
            code: "CFdak",
            color: "As Shown Above",
            priceRand: "R18 839",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R20 399",
            oldOfferCents: ".95",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "404",
            group: "Combo",
            name: "Prince & Ndlovu Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Prince_Pieces/Ndlovu_Prince_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Prince_Pieces/Ndlovu_Prince_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Prince_Pieces/Prince_CT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Prince_Pieces/Prince_PU.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Prince_Pieces/Ndlovu_DT.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Prince_Pieces/Ndlovu_Server.jpg') ,
            code: "CFpak",
            color: "As Shown Above",
            priceRand: "R22 764",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R24 649",
            oldOfferCents: ".95",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "405",
            group: "Combo",
            name: "Satao Round Table Full Set",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Round_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Round_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Round_CT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_PU.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Server.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Round_CT.jpg') ,
            code: "CFiak",
            color: "As Shown Above",
            priceRand: "R17 361",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R18 799",
            oldOfferCents: ".95",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "406",
            group: "Combo",
            name: "Satao Full Set",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Rectangle_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Rectangle_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_CT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_PU.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Server.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_CT.jpg') ,
            code: "CFjak",
            color: "As Shown Above",
            priceRand: "R18 054",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R19 549",
            oldOfferCents: ".95",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "407",
            group: "Combo",
            name: "Savuti Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Pieces/Savuti_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Pieces/Savuti_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Pieces/Savuti_CT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Pieces/Savuti_Server.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Pieces/Savuti_CT.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Pieces/Savuti_Server.jpg') ,
            code: "CFkak",
            color: "As Shown Above",
            priceRand: "R12 282",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R13 299",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "408",
            group: "Combo",
            name: "Tusker Set",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_Rectangle_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_Rectangle_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_PU_Option.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_Rect_DT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_01_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_02_PU.jpg') ,
            code: "CFlak",
            color: "As Shown Above",
            priceRand: "R18 562",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R20 099",
            oldOfferCents: ".95",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Choose onee of two Plasma Units. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "409",
            group: "Combo",
            name: "Tusker Round Table Set",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_Round_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_Round_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_PU_Option.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_Round_DT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_01_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Tusker_Pieces/Tusker_02_PU.jpg') ,
            code: "CFmak",
            color: "As Shown Above",
            priceRand: "R16 946",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R18 349",
            oldOfferCents: ".95",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Choose onee of two Plasma Units. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "410",
            group: "Combo",
            name: "Savuti & Tusker Combo Set 1",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_Round_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_Round_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_PU_Option.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_CT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Tusker_Round_DT.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Server.jpg') ,
            code: "CFnak",
            color: "As Shown Above",
            priceRand: "R29 136",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R31 549",
            oldOfferCents: ".95",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Choose onee of two Plasma Units. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "411",
            group: "Combo",
            name: "Savuti & Tusker Combo Set 2",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_Rect_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_Rect_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_PU_Option.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_CT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Tusker_Rect_DT.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Server.jpg') ,
            code: "CFoak",
            color: "As Shown Above",
            priceRand: "R30 752",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R33 299",
            oldOfferCents: ".95",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Choose onee of two Plasma Units. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "412",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "CFqak",
            color: "As Shown Above",
            priceRand: "R22 764",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R24 649",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "413",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "414",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "415",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "416",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "417",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "418",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "419",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "420",
            group: "Combo",
            name: "Bali Lounge Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_LS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_LS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_PU.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_CT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Bali_Pieces/Bali_CT.jpg') ,
            code: "CFaak",
            color: "As Shown Above",
            priceRand: "R9 973",
            priceCents: ".99",
            multiOptions: false,
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R10 799",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "421",
            group: "Combo",
            name: "Javari Lounge Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_LS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_LS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_PU.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_CT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_CT.jpg') ,
            code: "CFbak",
            color: "As Shown Above",
            priceRand: "R12 467",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R13 499",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "422",
            group: "Combo",
            name: "Prince Lounge Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Prince_Pieces/Prince_LS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Prince_Pieces/Prince_LS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Prince_Pieces/Prince_PU.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Prince_Pieces/Prince_CT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Prince_Pieces/Prince_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Prince_Pieces/Prince_CT.jpg') ,
            code: "CFeak",
            color: "As Shown Above",
            priceRand: "R6 833",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R7 399",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "423",
            group: "Combo",
            name: "Rex Lounge Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Rex_Pieces/Rex_LS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Rex_Pieces/Rex_LS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Rex_Pieces/Rex_PU.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Rex_Pieces/Rex_CT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Rex_Pieces/Rex_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Rex_Pieces/Rex_CT.jpg') ,
            code: "CFfak",
            color: "As Shown Above",
            priceRand: "R9 604",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R10 399",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "424",
            group: "Combo",
            name: "Ryan Full Drawer Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_Full_LS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_Full_LS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_PU.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_Full_Drawer_CT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_Full_Drawer_CT.jpg') ,
            code: "CFgak",
            color: "As Shown Above",
            priceRand: "R8 265",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R8 949",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "425",
            group: "Combo",
            name: "Ryan Half Drawer Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_Half_LS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_Half_LS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_PU.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_Half_Drawer_CT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ryan_Pieces/Ryan_Half_Drawer_CT.jpg') ,
            code: "CFhak",
            color: "As Shown Above",
            priceRand: "R8 265",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R8 949",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "426",
            group: "Combo",
            name: "Satao Lounge Round Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Round_LS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Round_LS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_PU.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Round_CT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Round_CT.jpg') ,
            code: "CFiak",
            color: "As Shown Above",
            priceRand: "R11 774",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R12 749",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "427",
            group: "Combo",
            name: "Satao Lounge Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Rect_LS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_Rect_LS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_CT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_PU.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_CT.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Satao_Pieces/Satao_PU.jpg') ,
            code: "CFjak",
            color: "As Shown Above",
            priceRand: "R12 467",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R13 499",
            oldOfferCents: ".99",
            size: "***",
            description: "Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "428",
            group: "Combo",
            name: "Savuti Tusker Lounge Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_LS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_LS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_PU_Option.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_CT.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Tusker_01_PU.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Tusker_02_PU.jpg') ,
            code: "CFnak",
            color: "As Shown Above",
            priceRand: "R12 097",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R13 099",
            oldOfferCents: ".99",
            size: "***",
            description: "Choose one of 2 Plasma Unit options. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "429",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "430",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "431",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "432",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "433",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "434",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "435",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "436",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "437",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "438",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "439",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "440",
            group: "Combo",
            name: "Javari Dining Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_DS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_DS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_DT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_Server.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_DT.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Javari_Pieces/Javari_Server.jpg') ,
            code: "CFbak",
            color: "As Shown Above",
            priceRand: "R16 392",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R17 749",
            oldOfferCents: ".99",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "441",
            group: "Combo",
            name: "Ndlovu Dining Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_DS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_DS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_DT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_Server.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_DT.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Ndlovu_Pieces/Ndlovu_Server.jpg') ,
            code: "CFdak",
            color: "As Shown Above",
            priceRand: "R13 159",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R14 249",
            oldOfferCents: ".99",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "442",
            group: "Combo",
            name: "Savuti Tusker Dinig Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_Rect_DS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_Rect_DS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Tusker_Rect_DT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Server.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Tusker_Rect_DT.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Server.jpg') ,
            code: "CFoak",
            color: "As Shown Above",
            priceRand: "R18 654",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R20 199",
            oldOfferCents: ".99",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "443",
            group: "Combo",
            name: "Savuti Tusker Round Dinig Table Combo",
            img:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_Round_DS_Group.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Tusker_Round_DS_Group.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Tusker_Round_DT.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Server.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Tusker_Round_DT.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Specials&Cmbos/Savuti_Tusker_Pieces/Savuti_Server.jpg') ,
            code: "CFnak",
            color: "As Shown Above",
            priceRand: "R17 038",
            priceCents: ".99",
            inProvinceDelivery: "R 900",
            soldOut: 1,
            specialOffer: true,
            oldOfferRand: "R18 449",
            oldOfferCents: ".99",
            size: "***",
            description: "Please Note: Chairs are not included with this order. Orders are placed to the Manufacturers upon Payment & may take from 4 to 6 weeks to be Delivered to your Doorstep",
        },

        {
            id: "444",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "445",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "446",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "447",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "448",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "449",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "450",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "451",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "452",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "453",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "454",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "455",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "456",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "457",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "458",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "459",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "460",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "461",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "462",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "463",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "464",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "465",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "466",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "467",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "468",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "469",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "470",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "471",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "472",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "473",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "474",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "475",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "476",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "477",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "478",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        {
            id: "479",
            group: "Other",
            name: "Mirrors Comming Soon 3",
            img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
            img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
            img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
            img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
            img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
            code: "FDaap",
            color: "As Shown Above",
            priceRand: "R4 999",
            priceCents: ".99",
            inProvinceDelivery: "R 800",
            soldOut: 252,
            specialOffer: true,
            oldOfferRand: "R6 999",
            oldOfferCents: ".50",
            size: "0000 x 000 x 000",
            description: "Small Description to display further information",
        },

        // -----------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------
        //                             From this Point Onwards Edits are required
        // -----------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------
        // ---------------------- Office Set Listings Start here ------------------------
        
//         {
//             id: "60",
//             group: "Office",
//             name: "Office Sets Comming Soon",
//             img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaas",
//             color: "As Shown Above",
//             priceRand: "R5 595",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 999,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "61",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "62",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "63",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "64",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "65",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "66",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "67",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "68",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "69",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "70",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "71",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "72",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "73",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "74",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "75",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "76",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "77",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "78",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "79",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "60",
//             group: "Office",
//             name: "Office Sets Comming Soon",
//             img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaas",
//             color: "As Shown Above",
//             priceRand: "R5 595",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 999,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "61",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "62",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "63",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "64",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "65",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "66",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "67",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "68",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "69",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "70",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "71",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "72",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "73",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "74",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "75",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "76",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "77",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "78",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "79",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "60",
//             group: "Office",
//             name: "Office Sets Comming Soon",
//             img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaas",
//             color: "As Shown Above",
//             priceRand: "R5 595",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 999,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "61",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "62",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "63",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "64",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "65",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "66",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "67",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "68",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "69",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "70",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "71",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "72",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "73",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "74",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "75",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "76",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "77",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "78",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "79",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "60",
//             group: "Office",
//             name: "Office Sets Comming Soon",
//             img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaas",
//             color: "As Shown Above",
//             priceRand: "R5 595",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 999,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "61",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "62",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "63",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "64",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "65",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "66",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "67",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "68",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "69",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "70",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "71",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "72",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "73",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "74",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "75",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "76",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "77",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "78",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "79",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "60",
//             group: "Office",
//             name: "Office Sets Comming Soon",
//             img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaas",
//             color: "As Shown Above",
//             priceRand: "R5 595",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 999,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "61",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "62",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "63",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "64",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "65",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "66",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "67",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "68",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "69",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "70",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "71",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "72",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "73",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "74",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "75",
//             group: "Office",
//             name: "Office Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaax",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "76",
//             group: "Office",
//             name: "Office Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaat",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "77",
//             group: "Office",
//             name: "Office Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaau",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "78",
//             group: "Office",
//             name: "Office Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaav",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "79",
//             group: "Office",
//             name: "Office Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaw",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         // -----------------  Outdoor Sets Start Here -----------------------
        
//         {
//             id: "80",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon",
//             img:require('../../Components_Images/Product_Listing/Sample.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaay",
//             color: "As Shown Above",
//             priceRand: "R5 595",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 999,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "81",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaz",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "82",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaba",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "83",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabb",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "84",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabc",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "85",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabd",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "86",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaz",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "87",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaba",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "88",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabb",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "89",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabc",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "90",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabd",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "91",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaz",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "92",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaba",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "93",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabb",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "94",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabc",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "95",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 5",
//             img:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabd",
//             color: "As Shown Above",
//             priceRand: "R15 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 50,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },

//         {
//             id: "96",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 1",
//             img:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaaz",
//             color: "As Shown Above",
//             priceRand: "R7 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "97",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 2",
//             img:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDaba",
//             color: "As Shown Above",
//             priceRand: "R12 000",
//             priceCents: ".00",
//             inProvinceDelivery: "R 800",
//             soldOut: 5,
//             specialOffer: false,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "98",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 3",
//             img:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabb",
//             color: "As Shown Above",
//             priceRand: "R4 999",
//             priceCents: ".99",
//             inProvinceDelivery: "R 800",
//             soldOut: 252,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         },
        
//         {
//             id: "99",
//             group: "Outdoor",
//             name: "Outdoor Sets Comming Soon 4",
//             img:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_1:require('../../Components_Images/Product_Listing/Sample_1.jpg') ,
//             img_2:require('../../Components_Images/Product_Listing/Sample_2.jpg') ,
//             img_3:require('../../Components_Images/Product_Listing/Sample_3.jpg') ,
//             img_4:require('../../Components_Images/Product_Listing/Sample_4.jpg') ,
//             img_5:require('../../Components_Images/Product_Listing/Sample_5.jpg') ,
//             code: "FDabc",
//             color: "As Shown Above",
//             priceRand: "R26 999",
//             priceCents: ".95",
//             inProvinceDelivery: "R 800",
//             soldOut: 0,
//             specialOffer: true,
//             oldOfferRand: "R6 999",
//             oldOfferCents: ".50",
//             size: "0000 x 000 x 000",
//             description: "Small Description to display further information",
//         }


        
 ]
