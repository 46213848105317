import '../../Components_Style/Internal/On_Special.css';
import ProductCard from '../Internal/Product_Card';
import ProductCardsData from '../Internal/Product_Data';

export default function LoungeOnSpecial() {

    const productElements = ProductCardsData.map(productCard => {
        return <ProductCard 
            key={productCard.id}
            {...productCard}
        />
    });

  return (
   <div className='Container_On_Special'>

    <div className='Container_Specials_Box'>

            <h1> LOUNGE SPECIALS </h1>
        <div className='Specials_Box'>

            <section className='Specials_Display'>
                {productElements[204]}
            </section>
    
            <section className='Specials_Display'>
                {productElements[205]}
            </section>
    
            <section className='Specials_Display'>
                {productElements[215]}
            </section>

        </div>

    </div>

   </div>
  );
}
