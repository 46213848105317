import About from '../Pages/About';
import Contact from '../Pages/Contact';


export default function StaticPages () {
  return (
    <div className="Container_Body">
        <About/>
        <Contact/>
    </div>
  );
}