import '../../Components_Style/Internal/Top_Sellers.css';
import ProductCard from '../Internal/Product_Card';
import ProductCardsData from '../Internal/Product_Data';

export default function ComboTopSellers() {

    const productElements = ProductCardsData.map(productCard => {
        return <ProductCard 
            key={productCard.id}
            {...productCard}
        />
    }) 

  return (
   <div className='Container_Top_Sellers'>
    <h1>Top Combo Deals</h1>

    <div className='Container_Top_Sellers_Box'>

        <section className='Top_Sellers_Display'>
            {productElements[401]}
        </section>
        <section className='Top_Sellers_Display'>
            {productElements[411]}
        </section>
        <section className='Top_Sellers_Display'>
            {productElements[425]}
        </section>

    </div>

   </div>
  );
}
