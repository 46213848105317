import '../../../../Pages_Style/Products.css';
import '../../../../Components_Style/Page_Header.css';
import ProductCardsData from '../../../../Components/Internal/Product_Data';
import ProductCard from '../../../../Components/Internal/Product_Card';

const productElements = ProductCardsData.map(productCard => {
    return <ProductCard 
    key={productCard.id}
    group={productCard.group}
    {...productCard}
    />
}) 

export default function DineTables() {
  
    return (
   
    <div className='Container_Product_Display'>

            <div className='Display_Row Row_1'>

                <section className='Cards_Display card1'>
                    {productElements[101]}
                </section>
                <section className='Cards_Display'>
                    {productElements[102]}
                </section>
                <section className='Cards_Display'>
                    {productElements[103]}
                </section>
                <section className='Cards_Display'>
                    {productElements[104]}
                </section>
                <section className='Cards_Display'>
                    {productElements[105]}
                </section>

            </div>

             <div className='Display_Row Row_2'>

                <section className='Cards_Display'>
                    {productElements[106]}
                </section>
                <section className='Cards_Display'>
                    {productElements[399]}
                </section>
                <section className='Cards_Display'>
                    {productElements[399]}
                </section>
                <section className='Cards_Display'>
                    {productElements[399]}
                </section>
                <section className='Cards_Display'>
                    {productElements[399]}
                </section>

            </div>

            {/*<div className='Display_Row Row_3'>

                <section className='Cards_Display'>
                    {productElements[1]}
                </section>
                <section className='Cards_Display'>
                    {productElements[2]}
                </section>
                <section className='Cards_Display'>
                    {productElements[3]}
                </section>
                <section className='Cards_Display'>
                    {productElements[4]}
                </section>
                <section className='Cards_Display'>
                    {productElements[5]}
                </section>

            </div>

            <div className='Display_Row Row_4'>

                <section className='Cards_Display'>
                    {productElements[1]}
                </section>
                <section className='Cards_Display'>
                    {productElements[2]}
                </section>
                <section className='Cards_Display'>
                    {productElements[3]}
                </section>
                <section className='Cards_Display'>
                    {productElements[4]}
                </section>
                <section className='Cards_Display'>
                    {productElements[5]}
                </section>

            </div> */}

        </div>
       
  );
}
