import '../../Components_Style/Internal/On_Special.css';
// import Newsletter from '../Newsletter';
import ProductCard from '../Internal/Product_Card';
import ProductCardsData from '../Internal/Product_Data';

export default function OnSpecial() {

    const productElements = ProductCardsData.map(productCard => {
        return <ProductCard 
            key={productCard.id}
            {...productCard}
        />
    });

  return (
   <div className='Container_On_Special'>

    <div className='Container_Specials_Box'>

            <h1> TOP SPECIAL OFFERS</h1>
        <div className='Specials_Box'>

            <section className='Specials_Display'>
                {productElements[0]}
            </section>
    
            <section className='Specials_Display'>
                {productElements[411]}
            </section>
    
            <section className='Specials_Display'>
                {productElements[215]}
            </section>
    
            <section className='Specials_Display'>
                {productElements[401]}
            </section>
    
            <section className='Specials_Display'>
                {productElements[103]}
            </section>

            <section className='Specials_Display'>
                {productElements[5]}
            </section>

            <section className='Specials_Display'>
                {productElements[101]}
            </section>

            <section className='Specials_Display'>
                {productElements[205]}
            </section>


        </div>

    </div>

    {/* <Newsletter/> */}

   </div>
  );
}
